$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$blue-color: #0090ff;
$gray-color-2: #292f33;
$semi-white: #e9f5fd;
.salon__checkout-giftcard-modal {
  .ant-modal-content {
    padding: 0;
    .ant-modal-header {
      padding: 20px 40px;
      margin: 0;
    }
    .ant-modal-body {
      padding: 20px 40px;
      max-height: unset !important;
    }
    .ant-modal-header {
      margin: 0;
    }
    .ant-modal-body {
      padding-bottom: 40px;
    }
  }

  &-title {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(41, 47, 51, 1);
  }

  &-form {
    .salon__form-input .ant-input-affix-wrapper {
      gap: 0;
      padding: 0 8px;
    }
    .salon__form-input .ant-input {
      padding: 10px 4px !important;
    }
    .salon__form-input .ant-input {
      padding: 12px 10px !important;
    }
  }
  &-title {
    color: $gray-color-2;
    font-family: $font-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    &-value {
      color: $gray-color-2;
      font-family: $font-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }
  }
}
