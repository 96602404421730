.salon {
  &__numeric-keyboard-require {
    display: flex;
    gap: 8px;

    .hg-theme-default {
      background-color: #ffff;
      padding: 0;

      .hg-row:not(:last-child) {
        margin-bottom: 8px !important;
      }

      .hg-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 8px 8px;
        padding: 0;
      }

      .hg-button {
        list-style: none;
        flex: calc(100% / 3 - 14.66px);
        max-width: calc(100% / 3 - 4.66px);
        height: 76px;
        padding: 16px 28px;
        border-radius: 12px;
        background: #66cdff;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'Inter';
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        margin-right: 0 !important;

        &.salon__blue {
          background: #0090ff;
        }

        &.salon__next {
          max-width: calc((100% / 12 - 7px) * 2);
          background: #0090ff;
          flex: calc((100% / 12 - 7px) * 2);
        }
      }

      .hg-button-backspace {
        background: #fff url('../../../public/assets/qr/backspace_24px.png') center no-repeat;
        background-color: rgba(238, 126, 104, 1);
      }
    }

    &-button {
      width: 122px;
      height: 328px;
      padding: 16px 32px;
      border-radius: 12px;
      background: #0090ff;
      border: 0px;

      &-text {
        color: #fff;
        font-family: 'Inter';
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}