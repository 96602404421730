@import 'styles/styles.scss';
@import 'react-toastify/dist/ReactToastify.css';
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .ant-drawer-content-wrapper {
    width: 280px !important;
  }

  .ant-layout-sider {
    width: 0 !important;
    min-width: 0 !important;
    flex: 0 0 auto !important;
  }

  .ant-drawer-body {
    padding: 0px !important;
  }

  .ant-layout-content {
    padding: 10px !important;
  }
}

.ant-tooltip .ant-tooltip-inner {
  background-color: #56595c;
}

.ant-layout {
  background-color: #f4faff;
}
