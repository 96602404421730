.salon {
  &__keyboard {
    display: flex;
    gap: 20px;

    .hg-theme-default {
      background-color: transparent;
      // opacity: 0.5;
      // background-color: #ffff;
      padding: 0;

      // .hg-row:not(:last-child) {
      //   margin-bottom: 8px !important;
      // }

      .hg-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 4px 0px;
        margin-bottom: 20px;
      }

      .hg-button {
        align-items: center;
        border: 1px solid #c7eef7;
        border-radius: 50%;
        color: #000;
        cursor: pointer;
        display: flex;
        flex: 95px;
        font-family: 'Inter', sans-serif;
        font-size: 45px;
        font-weight: 700;
        height: 95px;
        width: 95px;
        max-width: 95px;
        justify-content: center;
        line-height: normal;
        list-style: none;
        margin-right: 0 !important;

        padding: 16px 28px;
      }

      .hg-button-back {
        box-shadow: 0px 4px 4px 0px #ffffff40;
        border: none;

        // background: transparent url('../../../public/assets/qr/arrow.png') center no-repeat;
        background: rgba($color: #fff, $alpha: 0.025) url('../../../public/assets/qr/arrow.png') center no-repeat;
      }
      .hg-button-cancel {
        box-shadow: 0px 4px 4px 0px #ffffff40;
        border: none;
        // background: transparent url('../../../public/assets/qr/close_24px.png') center no-repeat;
        background: rgba($color: #fff, $alpha: 0.025) url('../../../public/assets/qr/close_24px.png') center no-repeat;
      }
    }

    &-button {
      width: 95px;
      height: 95px;
      padding: 16px 32px;
      border-radius: 50%;
      border: 0px;

      &-text {
        color: #000000;
        font-family: 'Inter';
        font-size: 45px;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
