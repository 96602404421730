$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$gray-color-2: #292f33;
$gray-color-3: #b4b6b8;
$error-color: #eb4c3b;
$required-color: #ff4d4f;
$blue-color: #0090ff;
.salon__form-input {
  &-label {
    margin-left: 5px;
    color: $required-color;
    font-family: $font-primary;
    font-size: 16px !important;
    line-height: 1;
    height: 35px !important;
  }
  &-number {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    border-radius: 8px;
    .ant-form-item-control-input {
      border: 1px solid $gray-color;
      height: 42px;
      padding: 10px 0;
      border-radius: 8px;
      &:focus-within {
        border-color: $blue-color;
      }
    }
    .ant-form-item-has-error {
      .ant-form-item-control-input {
        border: 1px solid $error-color !important;
      }
    }
    &-with {
      &-prefix {
        input {
          width: 85%;
          border: unset;
          outline: unset;
          margin-left: 24px;
        }
      }
      &-suffix {
        input {
          width: calc(100% - 46px);
          border: unset;
          outline: unset;
          margin-right: 24px;
        }
      }
      &-out-prefix {
        input {
          width: calc(100% - 40px);
          border: unset;
          outline: unset;
          margin-left: 10px;
        }
      }
      &-out-suffix {
        input {
          width: calc(100% - 46px);
          border: unset;
          outline: unset;
          margin-right: 10px;
        }
      }
      &-icon {
        input {
          width: calc(100% - 72px);
          border: unset;
          outline: unset;
          margin-left: 24px;
          margin-right: 24px;
        }
      }
    }
    &-prefix {
      // line-height: 1.2;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(10px, -55%);
    }
    &-suffix {
      // line-height: 1.2;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-10px, -55%);
    }
  }
  &-container {
    &-label {
      color: $gray-color-2;
      font-family: $font-primary;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
      width: 100%;
      min-height: 35px;
    }
    .ant-form-item-control-input-content {
      input {
        font-size: 16px !important;
        color: $gray-color-2 !important;
        &::placeholder {
          color: $gray-color-3 !important;
          font-size: 16px !important;
        }
      }
    }
    &-read-only {
      .salon__form-input-container-label {
        color: $gray-color-3 !important;
      }
      .ant-form-item-control-input {
        border-color: $gray-color-3 !important;
        &-content {
          input {
            color: $gray-color-3 !important;
            &::placeholder {
              color: $gray-color-3 !important;
            }
          }
        }
      }
      .salon__form-input-number {
        &-prefix,
        &-suffix {
          svg {
            path {
              fill: $gray-color-3 !important;
            }
          }
        }
      }
    }
  }
}
