$font-primary: 'Inter', sans-serif;
$gray-color-2: #292f33;
$gray-color-3: #b4b6b8;
$violet-color: #f3ebfa;
.salon__technician-item {
  &-container {
    border-radius: 8px;
    border: 1px solid $gray-color-3;
    background: $violet-color;
    width: 104px;
    height: 50px;
    padding: 10px 15px;
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &-avatar {
    width: 20px !important;
    height: 20px !important;
    border-radius: 8px;
  }
  &-name {
    color: $gray-color-2;
    font-family: $font-primary;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 15px */
    margin: 0;
    overflow: hidden;
    max-width: 7ch;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
