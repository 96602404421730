$color-00297A: #00297a;
$color-78797a: #78797a;
$color-fff: #fff;

$border-radius: 4px;

.salon__appointment-calendar-blocktime {
  & .ant-modal-content {
    border-radius: 12px;
    padding: 0;
  }

  &-heading {
    // padding-left: 16px;
  }

  &-list {
    overflow: auto hidden;
    margin-bottom: 24px;
    gap: 8px;
    padding: 10px 0 8px;

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-00297A;
      border-radius: 8px;
    }

    & > .ant-btn {
      margin-left: auto;
      margin-right: 5px;
    }
  }

  &-item {
    width: 160px;
    height: 40px;
    padding: 0 12px;
    border-radius: $border-radius;
    align-items: center;
    gap: 8px;

    & > .ant-col {
      padding-left: 0 !important;
      position: static;
    }

    &-container {
      position: relative;
    }

    &-wrapper {
      background: $color-00297A;
      border-radius: $border-radius;
      position: static;
    }

    &-button {
      position: absolute;
      top: -7px;
      right: -7px;
      border-radius: 50%;
      border: 1px solid $color-78797a;
      background: $color-78797a;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;

      & > span {
        height: 10px;
        display: flex;
        align-items: center;
      }

      &:hover,
      &:active,
      &:focus {
        background: $color-78797a !important;
      }
    }

    &-title {
      color: $color-fff;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90px;

      &-wrapper {
        height: 26px;
      }
    }
  }

  &-popover {
    & .ant-popover-inner {
      border-radius: 12px;
      padding: 0;
      width: 150px;
    }

    &-button {
      display: flex;
      gap: 4px;
      align-items: center;

      &-icon {
        display: flex;
        height: 10px;
        align-items: center;
        svg {
          path {
            fill: #0090ff;
          }
        }
      }
    }

    &-list {
      max-height: 205px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      border-radius: 12px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #dbdddf;
        border-radius: 8px;
        margin: 8px 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-00297A;
        border-radius: 8px;
      }
    }

    &-item {
      padding: 8px 12px;
      width: 100%;

      &:first-child {
        border-radius: 12px 12px 0 0;
      }

      &:last-child {
        border-radius: 0 0 12px 12px;
      }

      &:hover {
        background: #e7ecf0;
      }

      &-name {
        display: block;
        background-color: $color-00297A;
        color: $color-fff;
        padding: 4px 8px;
        width: 94px;
        height: 25px;
        text-align: center;
        border-radius: $border-radius;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90px;
      }
    }
  }

  &-form {
    max-height: 307px;
    overflow: hidden auto;
    padding: 0;

    &-popup {
      padding: 0;
      border-radius: 0;

      .ant-select-item-option {
        padding: 8px 16px;
        font-weight: 500;
        font-size: 16px;
        &-active {
          background-color: #f4faff !important;
          color: #0090ff;
        }
      }

      .rc-virtual-list-scrollbar-thumb {
        background-color: #00297a !important;
        width: 4px !important;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
      margin: 8px 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-00297A;
      border-radius: 8px;
    }

    & .salon__form-checkbox-title {
      color: $color-78797a !important;
    }

    & .salon__form-date-picker-custom {
      &.ant-form-item {
        margin-bottom: 17px;

        & .ant-form-item-control-input-content {
          height: 42px;
        }
      }
    }

    &-period {
      & .ant-form-item {
        margin-bottom: 9px;
      }

      &-common {
        & > .ant-select-selector {
          align-items: center;

          & > .ant-select-selection-placeholder {
            font-size: 14px !important;
          }
        }
      }
    }

    &-repeat {
      margin-bottom: 8px;

      &-every-number {
        margin-bottom: 0;

        & .ant-input-number {
          border-color: #292f33;
          width: 48px;

          &-handler {
            border: 0;

            &-wrap {
              right: -30px;
              opacity: 1;
              height: 60%;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          &-input {
            height: 42px;
            width: 48px;
          }
        }
      }

      &-every-unit {
        & .ant-form-item {
          margin-bottom: 19px;
        }
      }

      &-on {
        display: block;
        color: #78797a;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 50%;
        border: 1px solid #d7d9db;
        margin: 11px 0 19px;

        &.active {
          background-color: #e9f5fd;
          border-color: #e9f5fd;
          color: #0090ff;
        }
      }
    }

    &-every {
      display: flex;
      align-items: center;
      margin-top: -19px;
    }

    &-never {
      &.ant-form-item {
        margin-bottom: 4px;
      }
    }

    &-notes {
      &.ant-form-item {
        margin-bottom: 10px;
      }
    }

    &-buttons {
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      margin-top: -4px;

      & .ant-btn {
        height: 38px !important;
        width: 100px;
        border-radius: 8px;
        padding: 0;
      }

      & > .ant-col {
        &:nth-of-type(2) {
          height: 38px;
        }
      }
    }

    &-submit {
      &.ant-form-item {
        margin-bottom: 0;
      }

      & .ant-form-item-control-input-content {
        display: flex;
        justify-content: end;
      }
    }
  }

  &-delete {
    margin-bottom: 0;
    padding-left: 20px;

    & .ant-col {
      max-width: 100%;
    }

    & .ant-radio-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      gap: 8px;
      margin-top: 5px;
    }
  }
}
