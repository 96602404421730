$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$gray-color-2: #292f33;
$gray-color-3: #b4b6b8;
$gray-color-4: #f4faff;
$error-color: #eb4c3b;
$blue-color: #0090ff;
.salon__form-search-input {
  margin: 0 !important;
  height: 42px;
  .ant-form-item-control-input-content {
    span {
      background-color: #f4faff;
      border-radius: 8px;
      border: 1px solid #f4faff !important;
      &:focus-within {
        border-color: $blue-color !important;
      }
    }
  }
  .ant-form-item-control-input {
    height: 42px !important;
  }
  .ant-input-affix-wrapper {
    padding: 0 11px !important;

    &:focus {
      box-shadow: none !important;
    }

    &-focused {
      box-shadow: none !important;
    }
  }
  .ant-input {
    height: 40px;
    min-width: 382px;
    padding: 10px 12px;
    border-radius: 8px;
    border: 1px solid #f4faff !important;
    background-color: #f4faff;
    color: $gray-color-2 !important;
    font-family: $font-primary;
    font-size: 16px !important;
    &:focus,
    &:active,
    &:hover {
      box-shadow: unset;
    }
    &::placeholder {
      color: $gray-color-3 !important;
      font-family: $font-primary;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
    }
  }
}
