$color-fff: #fff;
$color-00297A: #00297a;
$color-0090FF: #0090ff;
$color-D7D9DB: #d7d9db;
$color-78797A: #78797a;
$color-56595C: #56595c;
$color-A0A2A3: #a0a2a3;
$color-EBF4FA: #ebf4fa;
$color-292F33: #292f33;
$color-E0B005: #e0b005;
$color-B4B6B8: #b4b6b8;

$border-radius: 4px;
$rbc-resource-width: 176px;

.salon__appointment-calendar-filter-popover-sub {
  border-radius: $border-radius;
  width: 258px !important;
  min-width: none !important;
  position: fixed !important;
  &-active {
    background-color: #e7ecf0;
  }

  & .salon__form-search-input {
    width: 413px !important;
  }

  & .ant-popover-inner {
    padding: 0;
    box-shadow: -1px -1px 20px 0px rgba(0, 0, 0, 0.1);
  }

  & .salon__form-search-input {
    border: 1px solid $color-D7D9DB;
    border-radius: $border-radius $border-radius 0 0;
    height: 52px !important;
  }

  & .ant-form-item-control {
    max-width: 100%;

    &-input {
      height: 52px !important;
    }
  }

  & .ant-input-affix-wrapper {
    height: 52px;

    & svg {
      width: 16px;
      height: 16px;
    }

    &:focus {
      box-shadow: none;
    }
  }

  & .ant-form-item-control-input-content {
    & span {
      background-color: transparent;
      border-color: transparent !important;
    }

    & .ant-input {
      background: transparent;
      border-color: transparent !important;
      height: 52px;
      font-size: 12px !important;

      &::placeholder {
        font-size: 12px !important;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  &-box {
    border: 1px solid $color-D7D9DB;
    padding: 1px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 52px;
    cursor: pointer;

    &:hover {
      background-color: #e7ecf0;
    }

    &:nth-of-type(2) {
      border-bottom: 0;
      border-top: 0;
    }

    &-text {
      margin-bottom: 0;
    }

    &-subtitle {
      color: $color-B4B6B8;
    }
  }

  &-list {
    overflow-y: auto;
    border: 1px solid $color-D7D9DB;
    border-top: 0;
    max-height: 260px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-00297A;
      border-radius: 8px;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: $color-00297A;
    padding: 0 8px;
    width: 50%;
    border-radius: $border-radius;
    height: 32px;

    &-wrapper {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 0 12px;
      height: 52px;

      &:hover {
        background-color: #e7ecf0;
      }
    }

    &-avatar {
      & .ant-image-img {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
    }

    &-name {
      color: $color-fff;
    }
  }
}
