$font-primary: 'Inter', sans-serif;
$gray-color-2: #292f33;
$blue-color: #0090ff;

.salon__appointment-setting {
  &-divider {
    width: auto;
    margin: 24px -40px;
  }
  & .ant-modal-content {
    padding: 0;
  }

  &-heading {
    display: inline-block;
    // padding: 20px 0 0 40px;
    padding: 0;
  }

  & .ant-modal-body {
    overflow-y: auto;
  }

  &-form {
    // padding: 14px 40px 0;
    padding: 0;

    &-group {
      &-content {
        color: #b4b6b8;
        font-family: $font-primary;
      }

      & > .ant-row {
        & > .ant-col {
          max-width: 100%;

          & .ant-checkbox-group {
            flex-direction: column;

            & .ant-checkbox-wrapper {
              margin-bottom: 11px;

              & .ant-checkbox-inner {
                height: 15px !important;
                width: 15px !important;
                border: 2px solid #292f33;
                border-radius: 3px;
              }
            }

            & .ant-checkbox-checked {
              & .ant-checkbox-inner {
                border-color: transparent;
              }
            }

            & .ant-checkbox-inner {
              &::after {
                border-color: #dadada !important;
                left: 20%;
                top: 50%;
              }
            }

            & .ant-checkbox-wrapper-checked {
              & .ant-checkbox-inner {
                border: 1px solid $blue-color !important;
              }

              & .salon__appointment-setting-form-group-content {
                color: $gray-color-2 !important;
              }
            }
          }
        }
      }
    }

    &-select {
      max-width: 100%;
      padding: 0 !important;

      &-order {
        padding-left: 25px !important;
        margin-left: -5px;
      }

      & .ant-col {
        max-width: 100%;
      }
    }

    &-button {
      margin-left: 0 !important;
      margin-right: 0 !important;

      &-common {
        width: 100px;
        height: 38px !important;
        padding: 0 !important;
        border-radius: 8px;
        text-align: center;
        margin-bottom: -4px;
      }
    }
  }

  &__turn-settings {
    .salon__form-input-number-suffix {
      display: flex;
      align-items: center;
      top: 0;
      transform: unset;
      height: 100%;
      right: 10px;
    }
    .salon__form-checkbox {
      .ant-checkbox {
        &-wrapper {
          align-items: start;
          > .ant-checkbox {
            align-self: flex-start;
            margin-top: 3px;
          }
        }
        &-input,
        &-inner {
          width: 21px !important;
          height: 21px !important;
        }
        &-inner {
          &::after {
            left: 4px;
            top: 44%;
            height: 13px !important;
            width: 7px !important;
          }
        }
      }
      .ant-form-item-control-input {
        min-height: 28px;
      }
      &-title {
        color: #292f33 !important;
      }
    }
    .ant-modal-footer {
      margin: 0;
    }
    .ant-modal-body {
      max-height: 575px;
      .ant-btn:hover {
        color: #0090ff;
      }
    }
    &-link {
      font-size: 16px;
      font-weight: 500;
      color: #0090ff;
      text-decoration: underline;
      height: unset;
      line-height: 19px;
      text-underline-offset: 3px;
    }
    &-select {
      padding: 0;
      .ant-select-selection-item {
        font-weight: 600;
      }
      .ant-select-item {
        padding: 8px 20px;
        &-option {
          &-active,
          &-selected {
            color: #0090ff !important;
          }
        }
      }
    }
  }

  &-turn-collapse {
    &-modal {
      .ant-modal-content {
        padding: 0px;
        .ant-modal-close {
          top: 16px !important;
        }
        .ant-modal-header {
          padding: 16px 20px !important;
        }
        .ant-modal-body {
          padding: 16px 20px !important;
        }
      }
    }
    .ant-collapse-item {
      &:first-child {
        .ant-collapse-header {
          padding-top: 12px !important;
          padding-bottom: 12px !important;
        }
      }
      .ant-collapse-header {
        border: 1px solid #d7d9db !important;
        padding: 12px 20px !important;
        align-items: center;
      }

      &-active {
        .ant-collapse-header {
          margin-top: 12px;
          border: 1px solid #d7d9db !important;
          border-radius: 12px !important;
          padding: 12px 20px !important;
          align-items: center;
        }
        .ant-collapse-content-box {
          padding: 0 20px 0 0 !important;
        }
      }
    }
  }
}
