$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$gray-color-1: #b4b6b8;
$gray-color-2: #292f33;
$error-color: #eb4c3b;
$required-color: #ff4d4f;
$blue-color: #0090ff;
.salon__form-input-number {
  &-container {
    &-label {
      color: $gray-color-2;
      font-family: $font-primary;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
      width: 100%;
      min-height: 35px;
    }
    input {
      border: unset;
      outline: unset;
      height: 100%;
      padding: 10px 0;
      font-size: 16px !important;
      margin-left: 10px;
      margin-right: 10px;
      width: 100%;
      color: $gray-color-2 !important;
      &::placeholder {
        font-size: 16px !important;
        color: $gray-color-1 !important;
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  &-label {
    margin-left: 5px;
    color: $required-color;
    font-family: $font-primary;
    font-size: 16px !important;
    line-height: 1;
  }
  &-format {
    .ant-form-item {
      margin-bottom: 0;
      width: 100%;
    }
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      height: 42px;
      border-radius: 8px;
      border: 1px solid $gray-color;
      &:focus-within {
        border-color: $blue-color;
      }
    }
    .ant-form-item-has-error {
      .ant-form-item-control-input-content {
        border: 1px solid $error-color !important;
      }
    }
    .ant-form-item-label {
      font-size: 16px !important;
      font-weight: 500;
      font-style: normal;
      font-family: $font-primary;
      color: $gray-color-2 !important;
      height: 35px !important;
      label {
        font-size: 16px !important;
        color: $gray-color-2 !important;
        display: flex;
        float: left;
      }
    }
    .ant-form-item-required {
      flex-direction: row-reverse;
      margin-left: -8px !important;
      // margin-bottom: 2px;
      &::before {
        margin-right: 0;
        margin-left: 5px;
      }
      &::after {
        position: unset !important;
        content: '' !important;
      }
    }
    &-read-only {
      .ant-form-item-control-input-content {
        border-color: $gray-color-1 !important;
        input {
          color: $gray-color-1 !important;
          &::placeholder {
            color: $gray-color-1 !important;
          }
        }
      }
      .ant-form-item-label {
        color: $gray-color-1 !important;
        label {
          color: $gray-color-1 !important;
        }
      }
    }
    &-disabled {
      border-color: #d9d9d9;
      .ant-form-item-control-input-content {
        background-color: rgba(0, 0, 0, 0.04);
        input {
          cursor: not-allowed;
          color: $gray-color-1 !important;
          &::placeholder {
            color: $gray-color-1 !important;
          }
        }
      }
    }
    &-with-icon {
      &__prefix,
      &__suffix {
        position: absolute;
        max-width: 20px;
        max-height: 20px;
        display: flex;
        align-items: center;
        justify-self: center;
      }
      &__prefix {
        left: 10px;
        & + input {
          padding-left: 36px;
        }
      }
      &__suffix {
        right: 10px;
      }
      input:has(+ .salon__form-input-number-format-with-icon__suffix) {
        padding-right: 36px;
      }
    }
  }
}
@media (max-width: 767px) {
  .salon__form-input-number-container input {
    padding: 0;
    &::placeholder {
      line-height: normal !important;
    }
  }
}
