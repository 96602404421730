$font-primary: 'Inter', sans-serif;
$white-color: #ffffff;
$black-color-2: #292f33;
$gray-color-1: #dbdddf;
$background-layout-color: #f4faff;
.salon__styled-header {
  padding: 0 !important;
  border-radius: 12px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  background-color: $white-color;
  &-box {
    padding: 20px 0;
    background-color: $background-layout-color;
  }
  &-title {
    color: $black-color-2;
    font-family: $font-primary;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    margin: 0 !important;
    display: flex;
    align-items: center;
  }
  &-container {
    position: sticky;
    align-self: flex-start;
    top: -20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    background-color: $background-layout-color;
    width: -webkit-fill-available;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: -24px;
    margin-left: -24px;
    margin-right: -24px;
  }
}
