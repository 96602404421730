.salon {
  &__step-two-custom-select{
    margin-top: 17px;
    @media (max-width: 767.98px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &__step-two-custom-service-list {
    flex-wrap: wrap !important;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
  }

  &__booking-online {
    &-pick-service {
      font-size: 16px;
      font-weight: 400;
      color: #b6b6b6;
      font-family: 'Inter', 'sans-serif';
    }

    &-checkbox-any {
      font-size: 16px;

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;

        .ant-checkbox {
          .ant-checkbox-inner {
            width: 39px;
            height: 30px;
            border-radius: 6px;

            &:after {
              width: 10px;
              height: 17px;
              left: 12px;
              top: 14px;
            }
          }
        }
      }
    }
  }

  &__information {
    background-color: rgba(244, 250, 255, 1);
    padding-top: 40px;
    padding-bottom: 28px;
    min-height: 100vh;
    overflow-x: hidden;
    flex-wrap: wrap;
    height: 530px !important;
    width: -webkit-fill-available;

    &-appointment-online-container {
      background: #fff;
      padding: 26px 32px 7px 32px;
      height: 227px;
      width: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      img {
        margin-top: 0 !important;
      }
    }

    &-appointment-container {
      overflow-x: auto;
      height: 108px;
      align-items: center;
      /* width */
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #dbdddf;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #00297a;
        border-radius: 8px;
      }
    }

    &-checkbox-terms {
      .ant-checkbox-inner {
        height: 21px !important;
        width: 21px !important;
        @media (max-width: 767.98px) {
          height: 15px !important;
          width: 15px !important;
        }
      }

      .ant-checkbox-inner {
        &::after {
          left: 4px;
          top: 45%;
          height: 13px !important;
          width: 6px !important;
          @media (max-width: 767.98px) {
            left: 3px;
            top: 49%;
            height: 8px !important;
            width: 5px !important;
          }
        }
      }

      .salon__form-checkbox-title {
        font-size: 24px !important;
        @media (max-width: 767.98px) {
          font-size: 12px !important;
        }
      }
    }

    &-form-terms {
      height: calc(100vh - 68px);
    }

    &-agree-terms {
      position: absolute;
      bottom: 28px;
      right: 40px;
      width: 184px !important;
      @media (max-width: 767.98px) {
        width: -webkit-fill-available !important;
        right: 0;
        left: 0;
        margin-left: 12px !important;
        margin-right: 12px !important;
      }

      &:disabled {
        border-radius: 8px;
        background: #b4b6b8;

        span {
          color: #fff;
        }
      }
    }

    &-disable-scroll {
      @media (max-width: 767px) {
        position: fixed;
        width: -webkit-fill-available;
      }
    }

    &-back {
      cursor: pointer;
      top: 10px;
      position: absolute;
      color: #292f33;
      font-family: 'Inter', 'sans-serif';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      display: flex;
      left: 0;
      text-decoration: none;
      gap: 20px;

      &-icon {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }

      &-title {
        color: #292f33;
        font-family: 'Inter', 'sans-serif';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .salon__online-appointment {
      &-title {
        color: #292f33;
        text-align: center;
        font-family: 'Inter', 'sans-serif';
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
        @media (max-width: 767.98px) {
          font-size: 20px;
        }
      }

      &-terms {
        &-container {
          padding-left: 40px;
          padding-right: 40px;
          @media (max-width: 1199px) {
            padding-left: 30px;
            padding-right: 30px;
          }
          @media (max-width: 767.98px) {
            padding-left: 15px;
            padding-right: 15px;
          }
          @media (max-width: 575.15px) {
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      }

      &-container {
        width: 1408px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0 24px;
        @media (max-width: 1199px) {
          width: 800px;
        }
        @media (max-width: 767.98px) {
          width: auto;
        }

        &-terms {
          margin-left: 132px;
          margin-right: 132px;
          @media (max-width: 1199px) {
            margin-left: 60px;
            margin-right: 60px;
          }
          @media (max-width: 767.98px) {
            margin-left: 30px;
            margin-right: 30px;
          }
          @media (max-width: 575.15px) {
            margin-left: 0;
            margin-right: 0;
          }

          &-content {
            color: #78797a;
            font-family: 'Inter', 'sans-serif';
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            @media (max-width: 767.98px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    &-step {
      align-items: center;
      // width: 385px;
      margin-left: auto;
      margin-right: auto;

      &-item {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #b4b6b8;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #b4b6b8;
      }

      .ant-steps-item-tail {
        padding: 4px 16px 4px 19px !important;
      }

      .ant-steps-item-wait .ant-steps-item-icon {
        background: transparent;
        border: 1px solid #b4b6b8;
        border-radius: 50%;
      }

      .ant-steps {
        &-icon {
          color: #b4b6b8 !important;
          font-weight: 700;
          font-size: 16px;
          line-height: normal;
        }

        &-item {
          &-active {
            .ant-steps-icon {
              color: #fff !important;
            }
          }

          &-content {
            margin-top: -1px !important;
          }

          &-title {
            margin-top: 8px;
            padding-right: 0;
            // color: #292f33;
            color: #b4b6b8 !important;
            text-align: center;
            font-family: 'Inter', 'sans-serif';
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          &-finish,
          &-process {
            .ant-steps-item-title {
              color: #292f33 !important;
            }
          }
        }
      }

      //fix bugs
      .ant-steps-item {
        width: 156px;

        .ant-steps-item-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          .ant-steps-item-icon {
            margin: 0;
          }

          .ant-steps-item-tail {
            margin-inline-start: calc(60px + 36px);
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: 120px;
          }
        }
      }
    }

    .salon__step-one {
      text-align: center;
      margin-top: 20px;

      .salon__online-appointment-container {
        width: 1184px;
      }

      &-title {
        color: #292f33;
        font-family: 'Inter';
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 40px;
      }

      .salon__form {
        &-wrap {
        }

        &-item {
          width: 100%;

          > .ant-form {
            display: flex;
            flex-wrap: wrap;
            max-width: 100%;
            gap: 20px;
            width: 100%;
          }

          .salon__custom-date-booking-online {
            border-color: #b4b6b8;

            input {
              background-color: unset;
            }
          }

          .salon__border-color {
            border-color: #292f33;
          }

          input {
            height: 56px;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            background-color: transparent;
            border-color: #292f33;

            &::placeholder {
              font-family: 'Inter';
              font-size: 20px !important;
              font-weight: 500 !important;
              line-height: 24px !important;
              letter-spacing: 0px !important;
            }

            &.ant-input-status-error {
              border-color: #eb4c3b !important;
            }
          }

          .ant-form-item {
            .ant-form-item-explain-error {
              display: flex;
            }

            .salon__form-input .ant-input {
              background-color: #f4faff;
            }

            margin-bottom: 0;

            &:nth-child(2) {
              flex: calc(60% - 10px);
              max-width: calc(60% - 10px);
            }

            &:nth-child(3) {
              flex: calc(40% - 10px);
              max-width: calc(40% - 10px);
            }
          }

          .salon__form-date-picker {
            .ant-picker {
              height: 56px;
              background-color: transparent;
            }

            &-box {
              height: 56px;
              width: 100%;
              display: flex;
              align-items: center;
              padding: 0 20px;
            }

            &-icon {
              top: 14px;
              right: 20px;
            }

            &-placeholder {
              font-size: 20px;
              font-weight: 500;
            }

            &-value {
              text-align: left;
              font-size: 20px;
              font-weight: 500;
            }
          }
        }

        &-keyboard {
          margin-top: 40px;

          .hg-theme-default {
            background-color: #f4faff;
            padding: 0;

            .hg-row {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              gap: 20px 15px;
              padding: 0;

              &:first-child {
                .hg-button {
                  background-color: #ee7e68 !important;

                  span {
                    font-size: 30px;
                  }
                }

                .hg-button-backspace {
                  background-color: #c93631 !important;
                }
              }
            }

            .hg-button {
              list-style: none;
              flex: calc(100% / 12 - 14.66px);
              max-width: calc(100% / 12 - 14.66px);
              // width: 84px;
              height: 76px;
              padding: 16px 28px;
              border-radius: 12px;
              background: #66cdff;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              font-family: 'Inter';
              font-size: 24px;
              font-weight: 700;
              line-height: normal;
              margin-right: 0 !important;
              margin-bottom: 16px;

              &.salon__blue {
                background: #0090ff;
              }

              &.salon__next {
                max-width: calc((100% / 12 - 7px) * 2);
                background: #0090ff;
                flex: calc((100% / 12 - 7px) * 2);
              }
            }

            .hg-button-backspace {
              background: #fff url('../../../../../public/assets/qr/backspace_24px.png') center no-repeat;
              background-color: rgba(238, 126, 104, 1);
            }

            .hg-button-space {
              max-width: calc((100% / 12 - 7px) * 2);
              flex: calc((100% / 12 - 7px) * 2);
            }

            .hg-button-shift {
              background: #0090ff;
            }

            .hg-button-symbols {
              background: #0090ff;
            }
          }

          ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 20px 16px;
            padding: 0;

            li {
              list-style: none;
              flex: calc(100% / 12 - 14.66px);
              max-width: calc(100% / 12 - 14.66px);
              // width: 84px;
              height: 76px;
              padding: 16px 28px;
              border-radius: 12px;
              background: #66cdff;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              font-family: 'Inter', 'sans-serif';
              font-size: 24px;
              font-weight: 700;
              line-height: normal;

              &.width-x2 {
                max-width: calc((100% / 12 - 7px) * 2);
                flex: calc((100% / 12 - 7px) * 2);
              }

              &.delete {
                background: #ee7e68;
              }

              &.blue {
                background: #0090ff;
              }
            }
          }
        }
      }
    }

    .salon__step-two {
      margin-top: 20px;

      &-right-main {
        width: 525px;
        /* width */
        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #dbdddf;
          border-radius: 8px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #00297a;
          border-radius: 8px;
        }

        .salon__step-two-right-main-item {
          width: 525px;
          flex-wrap: wrap;
          height: unset;
        }
      }

      &-custom {
        & .ant-col-14 {
          max-width: 100%;
        }

        &-booking {
          &-service {
            overflow-x: auto;
            width: 100%;
            padding-bottom: 5px;

            & > .salon__service-item-preview {
              min-width: 176px;
            }

            &::-webkit-scrollbar-track {
              border-radius: 10px;
              background-color: #dbdddf;
            }

            &::-webkit-scrollbar {
              height: 4px;
              background-color: #dbdddf;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: #00297a;
            }
          }
        }

        &-service-list {
          overflow-y: auto;
          max-height: 568px;
          display: flex !important;
          justify-content: space-between;
          gap: 20px;


          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #dbdddf;
          }

          &::-webkit-scrollbar {
            width: 4px;
            background-color: #dbdddf;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #00297a;
          }
        }

        &-checkbox {
          &.ant-checkbox-wrapper {
            display: flex;
            align-items: center;
          }

          & .ant-checkbox-inner {
            width: 39px !important;
            height: 30px !important;
            border-radius: 6px !important;

            &:after {
              width: 10px !important;
              height: 17px !important;
              left: 12px !important;
              top: 14px !important;
            }
          }
        }
      }

      &-top {
        display: flex;
      }

      &-bottom {
        margin-top: 70.5px;
        text-align: end;

        button {
          cursor: pointer;
          width: 184px;
          padding: 16px 28px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 12px;
          display: inline-flex;
          text-decoration: none;

          + button {
            margin-left: 12px;
          }

          span {
            text-align: center;
            font-family: 'Inter', 'sans-serif';
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        &-left {
          background: transparent;

          &-text {
            color: #b4b6b8;
          }
        }

        &-right {
          &-text {
            color: #fff;
          }
        }
      }

      &-title {
        color: #292f33;
        font-family: 'Inter';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .ant-card-bordered.ant-card .ant-card-body {
        padding: 0;
        border-radius: 0 0 8px 8px;
      }

      &-left {
        flex: 430px !important;
        max-width: 430px !important;
        border-right: 1px solid rgba(217, 217, 217, 1);
        padding-right: 30px;

        &-unselect {
          flex: unset !important;
          max-width: unset !important;
          //flex: 100%;
          //max-width: 100%;
        }

        &-wrap {
          cursor: pointer;
          overflow-x: auto;
          margin-top: 24px;
          max-width: 1062px;
          padding-bottom: 24px;

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #dbdddf;
          }

          &::-webkit-scrollbar {
            // width: 4px;
            height: 4px;
            background-color: #dbdddf;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #00297a;
          }
        }

        &-top {
          &-card {
            display: flex;
            gap: 24px 20px;

            + .salon__step-two-left-top-card {
              margin-top: 24px;
            }

            .ant-collapse {
              width: 100%;

              .ant-space-item {
                cursor: pointer;
              }
            }
          }

          .salon__service-item-preview {
            flex: calc((100% / 6) - 20px);
            max-width: calc((100% / 6) - 20px);
            min-width: 176px;
            display: flex;
            padding: 30px 10px 8px 10px;
            flex-direction: column;
            gap: 12px;
            box-shadow: -2.66667px 5.33333px 26.66667px 0px rgba(0, 0, 0, 0.1);

            &-title {
              text-align: left;
            }
          }
        }

        &-bottom {
          margin-top: 0 !important;
          width: 351px;
          padding-left: 53px;
          border-right: 1px solid rgba(217, 217, 217, 1);

          &-wrap {
            overflow-y: auto;
            margin-top: 23px;
            padding-top: 12px;
            padding-bottom: 12px;

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              border-radius: 10px;
              background-color: #dbdddf;
            }

            &::-webkit-scrollbar {
              // width: 4px;
              height: 4px;
              background-color: #dbdddf;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #00297a;
            }
          }

          &-checkbox {
            margin-top: 24px;
            font-size: 16px;

            .ant-checkbox-wrapper {
              display: flex;
              align-items: center;

              .ant-checkbox {
                .ant-checkbox-inner {
                  width: 39px;
                  height: 30px;
                  border-radius: 6px;

                  &:after {
                    width: 10px;
                    height: 17px;
                    left: 12px;
                    top: 14px;
                  }
                }
              }
            }

            &-text {
              margin-left: 4px;
              color: #292f33;
              font-family: 'Inter';
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
              /* 22.4px */
            }
          }

          &-card {
            display: flex;
            flex-direction: column;
            gap: 26px;
            height: 568px;
            overflow-y: auto;
            /* width */
            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #dbdddf;
              border-radius: 8px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #00297a;
              border-radius: 8px;
            }

            .salon__custom-card {
              &.active {
                border: 2px solid rgba(0, 144, 255, 1) !important;
              }

              position: relative;
              margin-right: 20px;
              // display: flex;
              width: 242px !important;
              height: 56px;
              padding: 12px 19px;
              // justify-content: center;
              // align-items: center;
              border-radius: 8px;
              border: 1px solid #b4b6b8;
              background: #f3ebfa;
              cursor: pointer;
              min-width: 176px;

              .ant-card-head {
                position: absolute;
                top: -28px;
                right: 17px;
                border-bottom: unset;

                .ant-card-head-wrapper {
                  position: absolute;
                }
              }

              &-des {
                font-family: 'Inter', 'sans-serif';
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0px;
                margin: 0;
              }
            }

            .ant-card-body {
              display: flex;
              align-items: center;
              // gap: 8px;
              width: 100%;
              height: 100%;
              gap: 0;

              .salon__custom-card-des {
                margin-left: 8px;
                max-width: calc(100% - 40px);
                word-break: break-all;
              }
            }
          }
        }
      }

      &-right {
        flex: unset !important;
        max-width: 510px !important;
        padding-left: 30px;

        &-main {
          &-item {
            overflow-y: auto;
            height: 500px;
            padding-right: 10px;
            //margin-top: 30px;

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              border-radius: 10px;
              background-color: #dbdddf;
            }

            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
              background-color: #dbdddf;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #00297a;
            }
          }

          &-data {
            margin-top: 24px;

            .salon__service-item-preview {
              margin-top: 20px;
              flex: calc((100% / 6) - 20px) 1;
              max-width: calc((100% / 6) - 20px);
              min-width: 176px;
              display: flex;
              padding: 30px 10px 8px 10px;
              flex-direction: column;
              height: 98px;
              gap: 12px;
              border-radius: 8px;
              border: 1px solid #292f33 !important;
              box-shadow: -2.66667px 5.33333px 26.66667px 0px rgba(0, 0, 0, 0.1);

              &-title {
                text-align: left;
              }
            }
          }

          &-no-data {
            text-align: center;
            margin-top: 24px;
          }

          &-des {
            margin-top: 12px;
            color: #dbdddf;
            font-family: 'Inter';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            /* 22.4px */
          }
        }
      }
    }

    .salon__step-three {
      align-items: center;
      text-align: center;
      margin-top: 100px;

      &-title {
        font-family: 'Inter', 'sans-serif';
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(41, 47, 51, 1);
        margin-bottom: 40px;
      }

      &-main {
        margin-top: 60px;

        .salon__online-appointment-btn,
        a {
          text-decoration: none;
          display: flex;
          width: 310px;
          padding: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 12px;
          border: 1px solid #0090ff;
          background: var(--background-03, rgba(0, 144, 255, 0.1));

          &::after {
            display: none;
          }
        }

        &-top {
          display: flex;
          width: 648px;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          flex-wrap: wrap;
          gap: 23px;

          .salon__online-appointment-btn {
            flex: calc(50% - 12.5px);
            max-width: 50%;
            height: 119px;
            background-color: transparent !important;
            border-color: #d7d9db !important;

            .salon__form-checkbox-title {
              color: #78797a !important;
            }

            &.ant-checkbox-wrapper {
              &-checked {
                background-color: #0090ff1a !important;
                border-color: #0090ff !important;

                .ant-checkbox-inner {
                  border: none !important;
                  background-color: transparent !important;
                }

                .salon__form-checkbox-title {
                  color: #0090ff !important;
                }
              }
            }

            .text {
              color: #0090ff;
              text-align: center;
              font-family: 'Inter', 'sans-serif';
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .ant-checkbox-wrapper:hover.ant-checkbox-inner {
              background-color: unset !important;
            }

            .ant-checkbox:focus-visible {
              outline: none;
            }

            .ant-checkbox-inner {
              width: 38px !important;
              height: 38px !important;
              border-radius: 50%;
              border-color: #78797a;
              background-color: transparent;

              &::after {
                display: none;
              }
            }

            .ant-checkbox-inner {
              border: none;
              background: #fff url('../../../../../public/assets/qr/icn_check_circle_gray.svg') center no-repeat;
              background-size: cover;
              border-radius: 50%;

              &::after {
                content: unset !important;
              }
            }

            .ant-checkbox-checked {
              .ant-checkbox-inner {
                border: none;
                background: #fff url('../../../../../public/assets/qr/icn_check_circle.svg') center no-repeat;
                background-size: cover;
                border-radius: 50%;

                &::after {
                  content: unset !important;
                }
              }

              &::after {
                border: unset !important;
                animation-name: none !important;
              }
            }
          }
        }

        .salon__handle-booking-online {
          .salon__handle {
            &-text {
              color: #fff;
              font-family: 'Inter';
              font-size: 24px;
              font-weight: 700;
              line-height: normal;
            }
          }
        }

        &-bottom {
          margin-top: 20px;
        }
      }

      &-custom-button {
      }
    }
  }
}

.salon__step-three-custom-button {
  cursor: pointer;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 38px;
  padding: 8px 24px;
  border-radius: 8px;
  background-color: #0090ff;
  color: #fff;
}

.salon {
  &__online-appointment {
    &-service-container {
      gap: 18px 41px;
      display: flex;
      flex-wrap: wrap;
      height: 568px;
      overflow-y: auto;
      /* width */
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #dbdddf;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #00297a;
        border-radius: 8px;
      }
    }

    &__technician-container {
      display: flex;
    }
  }

  &__service-item-preview {
    &.active {
      opacity: 0.4;
    }

    &__deactive-button {
      position: absolute;
      top: -3px;
      right: -6px;
    }
  }

  &__technician-item-preview {
    &.disable {
      user-select: none;
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }

    .ant-avatar {
      width: 32px;
      height: 32px;
      border: none;
      border-radius: 8px;
    }

    &__deactive-button {
      background-color: #78797a;
      width: 16px !important;
      height: 16px !important;
      border-radius: 50%;
      box-shadow: none;

      svg {
        width: 10px;
        height: 10px;

        path {
          fill: #ffffff;
        }
      }
    }

    &.active {
      border: 2px solid #0090ff !important;
    }
  }
}

.ant-steps-item-process,
.ant-steps-item-finish {
  .salon__information-step-item {
    background-color: #0090ff;
    border: 1px solid #0090ff;
    color: #ffffff;
  }
}

.salon__information-back {
  align-items: center;
}

.salon__styled-checkbox-container .ant-checkbox-wrapper,
.salon__information .ant-btn {
  max-width: 100%;
}

.salon__online-appointment__pick-a-time__title-select {
  font-weight: 700;
  color: #78797a;
  font-size: 20px;
}

@media (max-width: 1199px) {
  .salon__information .salon__step-one .salon__form-keyboard .hg-theme-default .hg-button {
    max-width: calc(100% / 12 - 8.66px);
    flex: calc(100% / 12 - 8.66px);
    height: 56px;
    padding: 8px 7px;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .salon__information .salon__step-one .salon__form-keyboard .hg-theme-default .hg-button-space {
    max-width: calc((100% / 12 - 2px) * 2);
    flex: calc((100% / 12 - 2px) * 2);
  }
  .salon__information .salon__step-one .salon__form-keyboard .hg-theme-default .hg-row {
    gap: 14px 9.4px;
  }
  .salon__information {
    padding: 56px 12px 16px !important;
  }
  .salon__information-back {
    gap: 8px;
    top: -40px;
  }
  .salon__information .salon__step-one .salon__form-keyboard .hg-theme-default .hg-button-backspace {
    background-size: 16px;
  }
}

@media (max-width: 767px) {
  .salon__row-wrap {
    padding-left: 0 !important;
    padding-right: 0 !important;

    > div {
      gap: 12px !important;
    }
  }
  .salon__online-appointment__information > h1 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    font-size: 18px !important;
    line-height: normal;
  }
  .salon__custom-m-t {
    margin-top: 24px !important;

    button {
      height: 43px !important;
      font-size: 16px !important;
      width: 100% !important;
    }
  }
  .salon__information-step .ant-steps-item {
    width: 80px;
  }
  .salon__information-step .ant-steps-item .ant-steps-item-container .ant-steps-item-tail {
    margin-inline-start: 48px;
    width: 100%;
    top: 8px;
  }
  .salon__online-appointment__step-item {
    background-color: #f4faff;
    width: 24px !important;
    height: 24px !important;
    font-size: 14px;
  }
  .salon__form-input {
    margin-bottom: 0;
  }
  .salon__information .ant-form-item-control-input-content,
  .salon__information .salon__step-one .salon__form-item input,
  .salon__information .salon__step-one .salon__form-item .salon__form-date-picker .ant-picker {
    height: 36px !important;
    align-items: center;

    &::placeholder {
      font-size: 14px !important;
      line-height: normal !important;
    }
  }

  .salon__information .salon__online-appointment__information .ant-form-item-control-input-content input {
    font-size: 14px !important;
    line-height: normal;

    &::placeholder {
      font-size: 14px !important;
      line-height: normal !important;
    }
  }
  .salon__row-wrap .ant-col {
    width: 100%;
  }
  .salon__information .salon__online-appointment-container {
    padding-left: 8px;
    padding-right: 8px;
  }
  .salon__information-main .salon__online-appointment-container {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-steps-item-title {
    width: 69px;
  }
  .salon__form-date-picker {
    margin-bottom: 0;
  }
  .ant-picker-suffix svg {
    path {
      fill: #292f33;
    }
  }
  .ant-steps-icon > div {
    font-size: 14px !important;
    background-color: #f4faff;
    width: 24px !important;
    height: 24px !important;
  }
  .salon__information .salon__step-one .salon__form-item .ant-form-item:nth-child(2),
  .salon__information .salon__step-one .salon__form-item .ant-form-item:nth-child(3) {
    flex: 100%;
    max-width: 100%;
  }

  .salon__information .ant-form-item-control-input-content input {
    font-size: 14px !important;

    &::placeholder {
      font-size: 14px !important;
      line-height: normal !important;
    }
  }
  .salon__information .salon__step-one .salon__form-item > .ant-form {
    gap: 12px;
  }
  .salon__information .salon__step-one-title {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 24px;
  }
  .salon__information .salon__step-one .ant-form-item-control-input-content,
  .salon__information .salon__step-one .salon__form-item input,
  .salon__information .salon__step-one .salon__form-item .salon__form-date-picker .ant-picker {
    height: 36px !important;
  }
  .salon__information .salon__step-two-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
  .salon__information .ant-btn {
    //width: 171px;
    padding: 12px 28px;
    gap: 8px;
    border-radius: 8px !important;
    height: 40px !important;
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }
  .salon__information .ant-btn span {
    font-size: 16px !important;
  }
  .salon__information .salon__step-two {
    display: block;
    flex-direction: column;
    min-height: calc(100vh - 160px);
  }
  .salon__information .salon__step-two-top {
    flex-direction: column !important;
    position: relative;
    flex: 1;
    border-bottom: 1px solid #dbdddf;
    gap: 24px;
  }
  .salon__information .salon__step-two-title {
    font-size: 14px;
    line-height: 140%;
  }
  .salon__information .salon__step-two-left {
    display: flex;
    flex: 100% !important;
    max-width: 100% !important;
    padding-right: 0;
    border-right: unset;
    overflow: hidden;
    height: calc(100vh - 350px);
  }
  .salon__information .salon__step-two-left-bottom {
    width: 100% !important;
    display: inline-block;
    overflow: hidden;
    padding-top: 9px;
    margin-top: 0;
    float: right;
    border-left: unset !important;
    border-right: unset !important;
    padding-left: 0 !important;
    height: fit-content !important;
  }
  .salon__online-appointment__service-container {
    display: block;
  }
  .salon__service-item-preview + .salon__service-item-preview {
    margin-top: 0 !important;
  }
  .salon__information .salon__step-two-left-wrap {
    padding-bottom: 8px;
    margin-top: 12px;
    height: calc(100% - 28px);
    overflow: unset !important;

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: #dbdddf;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #00297a;
    }
  }
  .salon__step-two-left-top {
    height: auto !important;
    width: 100% !important;
    max-width: 100% !important;
    flex: unset !important;
    padding-top: 9px;

  }
  .salon__step-two-left-top {
    .salon__step-two-custom-service-list {
      display: grid !important;
      grid-template-rows: repeat(2, 1fr);
      grid-auto-flow: column;
      gap: 24px 20px;
      padding-bottom: 10px;
    }
  }
  .salon__service-item-preview {
    height: 86px;
    width: 157px;
  }
  .salon__information .salon__step-two-left-top .salon__service-item-preview {
    margin: unset !important;
    min-width: 157px;
  }
  .salon__information .salon__step-two-right img {
    width: 30px;
  }
  .salon__information .salon__step-two-right-main-des {
    font-size: 12px;
  }
  .salon__information .salon__step-two-right {
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    padding-top: 0;
    padding-right: 4px;
    padding-bottom: 16px;
    height: max-content !important;
    border-bottom: 1px solid #d7d9db;

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar {
      height: 2px;
      background-color: #dbdddf;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #00297a;
    }
  }

  .salon__step-two-left {
    max-width: 100%;
    flex: 100%;
    padding-left: 0;
    width: 100%;
    height: calc(100% - 142px);
    border-right: 1px solid #dbdddf;
    padding-right: 4px;
  }

  .salon__information-back-title {
    font-size: 14px;
  }
  .salon__information-back svg {
    width: 7px;
    margin-top: -2px;
  }
  .salon__information .salon__step-two-left-bottom-wrap {
    overflow-x: unset;
    overflow-y: auto;
    margin-top: 0;
    height: fit-content !important;
    padding-top: 0;
  }
  .salon__information .salon__step-two-left-bottom-card {
    overflow-y: auto;
    max-height: 568px;
    display: flex !important;
    flex-direction: row;
    gap: 20px;
    height: fit-content !important;
    padding-top: 12px;
    padding-bottom: 20px;

    > .ant-card {
      + .ant-card {
        margin-top: 0 !important;
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: #dbdddf;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #00297a;
    }
  }
  .salon__information .salon__step-two-right-main {
    margin-top: 5px;
    overflow-y: auto;
    width: 100% !important;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: #dbdddf;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #00297a;
    }
  }
  .salon__information .salon__step-three-title {
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 20px;
  }
  .salon__step-three-main .salon__button-primary {
    width: 171px !important;
    margin-top: 24px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .salon__step-two-right-main-item {
    height: auto !important;
  }
  .salon__styled-checkbox-container .ant-checkbox-wrapper {
    width: 100% !important;
    height: 99px !important;
  }
  .salon__styled-checkbox-content {
    font-size: 16px !important;
    line-height: normal !important;
  }
  .salon__styled-checkbox-container {
    flex: calc(50% - 4px) !important;
    max-width: calc(50% - 4px) !important;
  }
  .salon__step-three-main-top {
    gap: 8px !important;
  }
  .salon__styled-checkbox-container span.ant-checkbox-inner,
  .salon__information .salon__step-three-main-top .salon__online-appointment-btn .ant-checkbox-inner {
    width: 24px !important;
    height: 24px !important;
  }
  .salon__information .salon__step-three,
  .salon__information .salon__step-three-main {
    margin-top: 20px;
  }
  body {
    overflow-x: hidden;
  }
  .salon__information .salon__technician-item-preview__deactive-button {
    width: 16px !important;
    height: 16px !important;
    padding: 0 !important;
  }
  .salon__information .salon__step-three-main-top .salon__online-appointment-btn {
    height: 99px;

    span {
      padding: 0 !important;
    }
  }
  .salon__information .salon__step-three-main-top .salon__online-appointment-btn .salon__form-checkbox-title,
  .salon__information .salon__step-three-main-top .salon__online-appointment-btn .text {
    font-size: 16px !important;
  }
  .salon__online-appointment__permission-col .ant-checkbox-wrapper {
    width: 100% !important;
  }
  .salon__online-appointment__permission-col {
    flex: calc(50% - 4px) !important;
    max-width: calc(50% - 4px) !important;
  }
  .salon__online-appointment__permission-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 99px !important;
  }
  .salon__row-wrap-custom {
    > div {
      margin-left: 0 !important;
      margin-right: 0 !important;
      gap: 8px !important;
    }
  }
  .salon__online-appointment__pick-a-time__title-select {
    font-size: 16px;
    line-height: 140%;
  }
  .Toastify__toast-container--top-right {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .salon__information .salon__step-two-right-main-item {
    padding-top: 4px;
    flex-wrap: nowrap !important;
    //flex-direction: row !important;
    overflow: unset !important;
    margin-right: 12px;
    padding-bottom: 8px;
    gap: 8px !important;

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar {
      height: 2px;
      background-color: #dbdddf;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #00297a;
    }
  }
  .salon__information .salon__step-two-left-bottom-card .salon__custom-card {
    min-width: 149px !important;
    width: 149px !important;
    padding: 12px;
    height: 44px;
    margin-right: 0;
  }
  .salon__information .salon__step-two-left-bottom-card .salon__custom-card-des {
    font-size: 12px;
    line-height: 150%;
  }
  .salon__information .salon__step-two-left-bottom-checkbox-text {
    font-size: 12px;
    line-height: 140%;
    margin-left: 0;
  }
  .salon__information .salon__step-two-left-bottom-checkbox label .ant-checkbox + span {
    padding: 0;
    padding-left: 4px;
  }
  .salon__technician-item-preview .ant-avatar {
    width: 20px;
    height: 20px;
  }
  .salon__technician-item-preview.active {
    opacity: 0.4;
  }
  .salon__form-date-picker .ant-picker-input input {
    font-size: 14px !important;
  }
  .salon__information .salon__step-two-left-bottom-checkbox {
    margin-top: 5px;
  }
  .salon__information .salon__step-two-right-main-no-data {
    margin-top: 77px;
  }
}
