.salon__checkout-discount-rewards-modal {
  .ant-modal-footer {
    padding: 20px 40px;
  }

  .ant-modal-content {
    padding: 0;
    .ant-modal-body {
      overflow-y: auto;
      max-height: 700px;
      padding-top: 0 !important;
    }
    .ant-modal-header {
      padding: 20px 40px;
    }
    .ant-modal-header {
      margin: 0;
    }
  }
  &-customer-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 450px;
  }
  &-title {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  &-text {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  &-form {
    width: 100%;
    .salon__checkout-discount-not-available {
      padding: 4px 8px;
      border-radius: 8px;
      background: #eb4c3b;
      color: #fff;
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .salon__checkout-discount-rewards-button-1 {
      // background-color: red;
      border: none !important;
      border-radius: 0;
      box-shadow: none;
      border-color: unset;
    }
    .salon__checkout-discount-rewards-button-2 {
      background-color: #0090ff1a;
      color: #0090ff !important;
      border-color: unset;
      box-shadow: none;
      border: none !important;
    }
    .salon__checkout-discount-rewards-button-3 {
      &:disabled {
        background-color: #d7d9db;
        color: #ffffff !important;
        border-color: unset;
        box-shadow: none;
        border: none !important;
      }
    }
    .salon__checkout-discount-rewards-button-4 {
      background-color: #99e3ff;
      color: #292f33 !important;
      border-color: unset;
      box-shadow: none;
      border: none !important;
      &:hover {
        background-color: #66cdff !important;
      }
    }
    .salon__checkout-discount-rewards-button-text {
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #292f33;
    }
    .salon__checkout-discount-rewards-available {
      background-color: #eb4c3b;
      color: #ffffff !important;
      padding: 4px 8px 4px 8px;
      border-radius: 8px;
      line-height: 14.52px;
    }
    .salon__checkout-discount-rewards-applied-btn {
      background-color: #d7d9db;
      color: #ffffff !important;
      border-color: unset;
      box-shadow: none;
      border: none !important;
    }
  }
  &-popup {
    &-title {
      font-family: 'Inter';
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0px;
      color: #eb4c3b;
    }
    &-content {
      font-family: 'Inter';
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      color: #78797a;

      &-strong {
        font-size: 16px;
        font-family: 'Inter';

        font-style: italic;
        font-weight: 400;
        line-height: 20px;
        color: #78797a;
      }
    }
  }
  .salon__checkout-discount {
    &-title {
      font-size: 16px;
      font-weight: 700;
      color: #292f33;
      &-sub {
        font-size: 12px;
        font-weight: 400;
        color: #78797a;
      }
    }
    &-button {
      font-size: 16px;
      font-weight: 400;
      color: #0090ff;
      font-family: Inter;
      font-size: 16px;
      cursor: pointer;
    }
    &-des {
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      color: #eb4c3b;
    }
  }
  &__tabs-list {
    border-bottom: 1px solid #d7d9db;
    .salon__button-tab-btn {
      width: 140px;
      min-width: unset;
      height: 38px;
      line-height: 22px;
      font-weight: 400;
    }
  }
  &__customer {
    padding-top: 17.5px;
    padding-bottom: 17.5px;
    border-bottom: 1px solid #d7d9db;
    &-close {
      width: 20px !important;
      height: 20px !important;
      padding: 0;
      margin: 0;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.salon__checkout-custom-discount-modal {
  .ant-modal-content {
    padding: 0;
    .ant-modal-header,
    .ant-modal-body {
      padding: 20px 40px 40px 40px;
    }
    .ant-modal-header {
      margin: 0;
    }
  }
  &-title {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(41, 47, 51, 1);
  }
  &-form {
    .salon__checkout-select-redeem {
      .ant-select {
        &-selector {
          border-bottom: 1px solid #78797a !important;
          border-top: unset !important;
          border-left: unset !important;
          border-right: unset !important;
          border-radius: unset !important;
          padding: 10px 0 !important;
        }
        &-open {
          .ant-select-arrow {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

.salon {
  &__modal__confirmation {
    .ant-modal-title {
      color: black;
      font-family: 'Inter';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .ant-modal-header {
      padding-bottom: 20px;
      border-bottom: 1px solid gray;
    }
    .ant-modal-content {
      padding: 32px 0;

      .anticon {
        display: none;
      }
      .ant-modal-confirm-body {
        justify-content: center;
      }

      .ant-modal-confirm-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28px;

        .ant-btn {
          width: 100px;
          height: 38px;
          font-size: 16px;
          font-weight: 700;
          box-shadow: none;
          &.ant-btn-default {
            border-color: #b4b6b8;
            color: #b4b6b8;
          }
          &.ant-btn-primary {
            background-color: #0090ff;
          }
        }
      }
    }
  }
}
