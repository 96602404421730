@import '../Form/FormDatePicker/formDatePicker.scss';

.salon {
  &__styled-date-picker {
    .ant-picker {
      border-color: #292f33;
      .ant-picker-suffix {
        svg {
          path {
            fill: #292f33;
          }
        }
      }
    }
  }
}
