$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$disable-color: #a0a2a3;
$white-color: #ffffff;
$blue-color: #0090ff;
.salon__form-button-submit {
  .ant-btn {
    height: 46px;
    padding: 12px;
    border-radius: 8px !important;
    border: 1px solid $blue-color;
    background: $blue-color;
    color: $white-color;
    font-size: 16px !important;
    font-family: $font-primary;
    font-weight: 700 !important;
    line-height: 1;
  }
  &-disable {
    background: $disable-color !important;
    border-color: $disable-color !important;
  }
}
