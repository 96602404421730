$font-primary: 'Inter', sans-serif;
.salon__button-icon {
  border: unset;
  padding: 0;
  outline: unset;
  box-shadow: unset;
  line-height: 1.2;
  color: #292f33 !important;
  font-size: 20px;
  font-family: $font-primary;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: auto;
  height: auto;
  z-index: 1;
  &:active,
  &:focus,
  &:hover {
    outline: unset;
    background: none !important;
  }
}
