.salon {
  &__custom {
    &-note {
      width: 929px;
      font-size: 20px;
    }

    &-text {
      font-size: 20px;
      font-weight: 700;
    }
  }

  &__online-appointment {
    &-info {
      background-color: #f4faff;
      &-title {
        font-weight: 400;
        font-size: 20px;
      }
    }

    &__deposit {
      &-header {
        background-color: #e5e5e5;
      }
      &-text {
        gap: 80px;
      }
    }

    &-new {
      &__deposit-title {
        font-size: 32px;
        font-weight: 600;
        color: #292f33;
        margin-bottom: 40px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 767px) {
  .salon {
    &__online-appointment {
      &__deposit {
        &-header > div {
          flex-direction: column;
          gap: 0px !important;
          align-items: start !important;
        }
      }

      &-new {
        &__deposit-title {
          font-size: 24px;
          font-style: normal;
        }
      }

      &-info {
        &-scroll {
          max-width: 1657px;
          overflow-x: auto;
          padding-right: 20px;
        }

        &-title {
          font-size: 18px;
          font-weight: normal;
        }
      }
    }

    &__custom {
      &-note {
        width: auto !important;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
      }

      &-d-f {
        flex-direction: column;
      }

      &-text {
        font-size: 18px;
      }
    }

    &__button {
      button {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .salon {
    &__online-appointment {
      &-info-scroll {
        overflow-x: auto;
        padding-right: 20px;
      }

      &__deposit {
        &-text {
          gap: 40px !important;
        }
      }
    }

    &__custom {
      &-note {
        width: 600px;
        padding-left: 10px;
      }
    }
  }
}
