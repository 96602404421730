$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$gray-color-2: #292f33;
$gray-color-3: #b4b6b8;
$blue-color: #0090ff;
$error-color: #eb4c3b;
.salon__ticket-card {
  &-checkbox {
    .ant-checkbox-inner {
      height: 15px !important;
      width: 15px !important;
      border: 2px solid $gray-color-2;
      border-radius: 3px;
      background: transparent !important;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: transparent;
        background-color: $blue-color !important;
      }
    }
    .ant-checkbox-inner {
      &::after {
        border-color: #dadada !important;
        left: 20%;
        top: 50%;
      }
    }
    &-title {
      color: #b4b6b8 !important;
      font-family: $font-primary;
    }

    //checked
    .ant-checkbox-wrapper-checked {
      .ant-checkbox-inner {
        border: 1px solid $blue-color !important;
      }
      .salon__form-checkbox-title {
        color: $gray-color-2 !important;
      }
    }

    &-first {
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $error-color !important;
        }
      }
    }
  }
  &-detail {
    &-customer {
      &-name {
        color: $gray-color-2;
        font-family: $font-primary;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 16.8px */
      }
      &-phone {
        color: $gray-color-2;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $font-primary;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 16.8px */
      }
    }
    &-quantity {
      color: $gray-color-2;
      font-family: $font-primary;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 14px */
    }
    &-technician {
      &-avatar {
        width: 12px;
        height: 12px;
        border-radius: 12px;
      }
      &-name {
        color: $gray-color-2;
        font-family: $font-primary;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 14px */
        margin-left: 4px;
      }
    }
    &-container {
      background: #66cdff;
      border-radius: 4px;
    }
  }
  &-container {
    width: 176px;
    height: 125px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #292f33;
    background: rgba(0, 144, 255, 0.1);
    /* Drop Shadow */
    box-shadow: -1px -1px 20px 0px rgba(0, 0, 0, 0.1);
    &-first {
      border-color: $error-color !important;
    }
    &-selected {
      border-color: $blue-color !important;
    }
    &-technician {
      display: flex;
      flex-direction: column;
      padding: 4px 8px;
      border-radius: 4px;
    }
    .slick-arrow {
      position: absolute;
      right: -8px !important;
      z-index: 5;
      top: 13px;
      transform: unset !important;
    }
    .slick-prev {
      right: 6px !important;
      left: unset;
      top: 5px;
    }
    .slick-next {
      top: 5px;
    }
  }
}
