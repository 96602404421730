$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$gray-color-1: #b4b6b8;
$white-color: #ffffff;
$blue-color: #0090ff;
$error-color: #eb4c3b;
.salon__button {
  &-primary {
    padding: 0;
    border-radius: 8px;
    border: 1px solid $blue-color;
    background: $blue-color;
    color: $white-color;
    font-family: $font-primary;
    font-weight: 700;
    line-height: 1;
    outline: unset !important;
    &:hover {
      box-shadow: unset !important;
    }
  }
  &-secondary {
    padding: 0;
    border-radius: 8px;
    border: 1px solid $blue-color;
    background: $white-color;
    color: $blue-color;
    font-family: $font-primary;
    font-weight: 700;
    line-height: 1;
    outline: unset !important;
    &:hover {
      box-shadow: unset !important;
    }
  }
  &-warning {
    padding: 0;
    border-radius: 8px;
    border: 1px solid $error-color !important;
    background: $white-color;
    color: $error-color !important;
    font-family: $font-primary;
    font-weight: 700;
    line-height: 1;
    outline: unset !important;
    &:hover {
      box-shadow: unset !important;
    }
  }
  &-cancel {
    padding: 0;
    border-radius: 8px;
    border: 1px solid $gray-color-1 !important;
    background: $white-color;
    color: $gray-color-1 !important;
    font-family: $font-primary;
    font-weight: 700;
    line-height: 1;
    outline: unset !important;
    &:hover {
      box-shadow: unset !important;
    }
    &-popup {
      padding: 0;
      border-radius: 8px;
      border: 1px solid $gray-color !important;
      background: $white-color;
      color: $gray-color !important;
      font-family: $font-primary;
      font-weight: 700;
      line-height: 1;
      outline: unset !important;
      &:hover {
        box-shadow: unset !important;
      }
    }
  }
}
