.salon__form-button-circle {
  &:disabled {
    path {
      fill: #ffffff;
    }
  }
  &-primary {
    border-radius: 8px;
    background: #0090ff;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    &:disabled {
      background: #b4b6b8 !important;
      border: unset !important;
    }
  }
  &-second {
    border-radius: 8px;
    background: #dbdddf;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    &:disabled {
      background: #b4b6b8 !important;
      border: unset !important;
    }
  }
}
