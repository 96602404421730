$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$gray-color-1: #dbdddf;
$gray-color-3: #b4b6b8;
$gray-color-4: #d9d9d9;
$gray-color-5: #c8cacc;
$gray-color-6: #d7d9db;
$white-color: #ffffff;
$blue-color: #0090ff;
$blue-color-2: #99e3ff;
$black-color-2: #292f33;
$semi-gray-color: #e7ecf0;
$error-color: #eb4c3b;
.salon__checkout {
  &-service {
    &-container {
      background-color: $white-color;
    }
  }
  &-bill-item-remove-btn {
    line-height: 10px !important;
  }
  &-bill-start {
    border-radius: 8px;
    background: $white-color;

    box-shadow: -1px -1px 20px 0px #0000001a;
    height: 100%;
    // margin: 0 4px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &-tap-on {
      &-container {
        margin-top: 80px;
        text-align: center;
      }
      &-title {
        color: $gray-color-3;
        text-align: center;
        font-family: $font-primary;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 36px */
        &-btn {
          color: $gray-color-3;
          text-align: center;
          font-family: $font-primary;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 36px */
          text-decoration-line: underline;
        }
      }
    }
  }
  &-table {
    &-customer {
      // border: 1px solid $gray-color-6;
      border-radius: 8px;
      .salon__custom-table {
        border-top: unset !important;
        .ant-table {
          border: 8px !important;

          &-thead {
            & .ant-table-cell {
              padding: 8px 12px;
            }
          }
        }
      }
    }
  }
  &-disable-draggable {
    opacity: 0.4;
    cursor: no-drop;
  }
  &-card {
    box-shadow: -1px -1px 20px 0px rgba(0, 0, 0, 0.1);
  }
  &-bill {
    &-service-dropzone {
      transition: height 300ms;
    }
    &-general-discount {
      padding: 0 0 25px 0;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        bottom: 12px;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 1px;
        width: 91.5%;
        background-color: $gray-color-1;
      }
    }
  }
  &-block {
    padding: 12px 20px;
    border-top: 2px solid $gray-color-5;
  }
  &-bill-item {
    &-warning {
      color: $error-color;
      font-family: $font-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      // line-height: 150%; /* 21px */
      line-height: 140%;
    }
    &-price {
      color: $black-color-2;
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      // line-height: 150%; /* 21px */
      line-height: 140%;
    }
    &-name {
      color: $black-color-2;
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      // line-height: 150%; /* 21px */
      line-height: 140%;
    }
  }
  &-service {
    &-price {
      color: $black-color-2;
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
    }
    &-name {
      color: $black-color-2;
      font-family: $font-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }
  &-technician {
    &-container {
      background-color: $semi-gray-color;
      padding: 8px 20px;
      width: 100%;
      &-active {
        background-color: $blue-color-2;
        padding: 8px 20px;
        width: 100%;
      }
    }
    &-avatar {
      width: 32px !important;
      height: 32px !important;
      border-radius: 8px;
    }
    &-name {
      color: $black-color-2;
      font-family: $font-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
      overflow: hidden;
      max-width: 25ch;
      text-overflow: ellipsis;
      // direction: rtl;
      text-align: left;
      margin-bottom: 0;
    }
    &-service {
      &-container {
        width: 100%;
        padding: 4px 20px 4px 40px;
        z-index: 1;
        position: relative;
        background-color: $white-color;
      }
      &-price {
        color: $blue-color;
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
      &-name {
        color: $black-color-2;
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
  }
  &-btn-update-service-checkin {
    border-radius: 8px;
    background: $blue-color !important;
    padding: 10px;
    margin: 0;
    width: 38px;
    height: 38px;
    &:disabled {
      background: $gray-color-3 !important;
      border: unset !important;
    }
  }
  &-tooltip {
    max-width: 442px !important;
  }
  &-list-services {
    padding: 16px 0;
    margin-top: 4px;
    margin-bottom: 8px;
    margin-right: 20px;
    border-top: 1px solid $gray-color-4;
    border-bottom: 1px solid $gray-color-4;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  &-service-item {
    border-radius: 8px;
    border: 1px solid $black-color-2;
    background: $white-color;
    width: 104px;
    height: 58px;
    padding: 10px 15px;
    display: flex;
    gap: 4px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &-title {
      color: $black-color-2;
      text-align: center;
      font-family: $font-primary;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 14px */
    }
  }
  &-edit-technician {
    border-radius: 8px;
    border: 1px solid $gray-color-3;
    background: $blue-color;
    width: 104px;
    height: 50px;
    padding: 10px 15px;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &-title {
      color: $white-color;
      font-family: $font-primary;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 15px */
    }
  }
  &-btn-collapse {
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 4px;
    background-color: rgba(0, 144, 255, 0.1) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 78px;
    width: 12px;
    padding: 10px 0;
    outline: unset;
    box-shadow: unset;
    border: unset;
    &:active,
    &:focus,
    &:hover {
      outline: unset;
    }
  }
  &-drop-zone {
    max-height: 50%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    &::before {
      content: '';
      position: absolute;

      width: 100%;
      height: 1px;
      background-color: #c8cacc;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $gray-color-1;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
  }
  &-customer {
    display: flex;
    flex-direction: column;
    &-title {
      color: $black-color-2;
      font-family: $font-primary;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-phone {
      color: $black-color-2;
      font-family: $font-primary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
    }
    &-subtitle {
      color: $blue-color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $font-primary;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 18px */
    }
  }
  &-container {
    height: calc(100% - 77px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 4px;
    &-btn-collapse {
      position: relative;
    }
    &-customer {
      padding: 16px 20px;
      background-color: #0090ff1a;
      height: 88px;
    }
    &-page {
      height: calc(100vh + 4px);
      margin: -24px;
      padding-top: 24px;
      overflow: hidden;
      .salon__styled-header-container {
        margin-top: -44px;
        top: -44px;
        .salon__page-container {
          border-radius: unset !important;
        }
      }
    }
  }
  &-form-search {
    // border-bottom: 1px solid #d7d9db;
    align-items: center;
    .ant-form-item {
      width: 100% !important;
    }
    background-color: $white-color;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    padding: 16px 20px 16px 20px;
    border-top-left-radius: 8px;

    &-input {
      background-color: #f6f9fb !important;
    }
  }
  &-col-container {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $gray-color-1;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
    &-mid {
      height: calc(100% - 88px) !important;
      background-color: $white-color;
      border-top: 1px solid $gray-color-6;
    }
    &-col-left {
      width: 53%;
      transition: width 300ms;
      background-color: $white-color;
      box-shadow: -1px -1px 20px 0px #0000001a;
      padding-left: 20px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding-top: 0;
      }

      // fix bug
      .salon__styled-collapse-main .ant-collapse-item:first-child .ant-collapse-header {
        padding-bottom: 20px !important;
      }
    }
    &-col-mid {
      width: 30%;
      height: 100%;
      transition: width 300ms;
      box-shadow: -1px -1px 20px 0px #0000001a;
    }
    &-col-right {
      width: 17%;
      height: 100%;
      transition: width 300ms;
      background-color: $white-color;
      box-shadow: -1px -1px 20px 0px #0000001a;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      .salon__form-search-input {
        .ant-input {
          min-width: unset !important;
          width: 100% !important;
        }
        .ant-form-item-control-input-content span {
          align-items: center;
        }
      }
    }
  }
  &-expand-width {
    &-left {
      width: 21%;
    }
    &-right {
      width: 49%;
    }
  }
}
