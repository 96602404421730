.salon__checkout-terminal-device-setting-modal {
  .ant-modal-footer {
    padding: 20px 40px;
  }
  .ant-modal-content {
    padding: 0;
    .ant-modal-header,
    .ant-modal-body {
      padding: 20px 40px;
    }
    .ant-modal-header {
      margin: 0;
    }
    .ant-modal-body {
      padding-bottom: 3px;
    }
  }
  &-disable-btn {
    background-color: #d7d9db;
    border-color: #d7d9db;
    color: #292f33;
    &:hover,
    &:active,
    &:focus {
      background-color: #d7d9db;
      border-color: #d7d9db;
      color: #292f33;
    }
  }
  &-title {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  &-text {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  &-popup {
    &-title {
      font-family: 'Inter';
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0px;
      color: #eb4c3b;
    }
    &-content {
      font-family: 'Inter';
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      color: #78797a;

      &-strong {
        font-size: 16px;
        font-family: 'Inter';

        font-style: italic;
        font-weight: 400;
        line-height: 20px;
        color: #78797a;
      }
    }
    .salon__checkout-terminal-device-setting-clover-modal {
      .salon__checkout-terminal-clover-custom-label {
        .ant-form-item-label {
          background-color: red !important;
        }
      }
      .ant-col .ant-form-item-label {
        label {
          height: 100px !important;
        }
      }
    }
  }
  &__tabs-list {
    width: 229px;
    .salon__button-tab-btn {
      width: 76px;
      height: 30px;
      max-width: 76px;
      min-width: 0;
      color: #b4b6b8;
    }
    .salon__button-tab .active {
      background-color: red !important;
    }
    .salon__button-tab .active-content {
      color: #fff !important;
    }
  }
  &__col {
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
  }
}
