.salon__checkout {
  &-list-checkin-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    height: 268px;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid #d7d9db;
    padding: 12px 20px 28px 20px;
    margin-top: 12px;
    // margin-left: -1px;
    // margin-right: -1px;
    .salon__checkin-card-container {
      width: 100%;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
  }
  &-modal-checkin {
    .ant-modal-body {
      overflow: auto;
    }
  }
  &-option-modal {
    .ant-modal-content {
      padding: 0;
      .ant-table-container {
        border-radius: 8px;
        border: 1px solid #d7d9db;
      }
      .ant-modal-header {
        padding: 20px 40px;
      }
      .ant-modal-body {
        padding: 20px 40px 0 40px;
        .ant-table-tbody {
          .ant-table-cell {
            border-top: 1px solid #d7d9db;
            &:first-child {
              border-top-left-radius: unset !important;
              border-bottom-left-radius: unset !important;
            }
            &:last-child {
              border-top-right-radius: unset !important;
              border-bottom-right-radius: unset !important;
            }
          }
          .ant-table-row {
            &:nth-child(2) {
              .ant-table-cell {
                border-top: unset;
              }
            }
          }
        }
        .salon__information .salon__step-one .salon__form-keyboard .hg-theme-default {
          background-color: #fff;
        }
      }
      .ant-modal-footer {
        padding: 20px 40px;
      }
      .ant-modal-header {
        margin: 0;
      }
    }
    // &-list {
    //   &-customer {
    //     .ant-modal-body {
    //       height: 358px !important;
    //     }
    //   }
    // }

    &-title {
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(41, 47, 51, 1);
    }

    &-form {
      .salon__form-input .ant-input-affix-wrapper {
        gap: 0;
        padding: 0 8px;
      }

      .salon__form-input .ant-input {
        padding: 10px 4px !important;
      }

      .salon__form-input .ant-input {
        padding: 12px 10px !important;
      }

      .salon__checkout-option-technician {
        &-radio {
          margin-top: 12px;
          .ant-radio-group {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }
        &-title {
          color: #292f33;
          font-family: 'Inter';
          font-size: 16px;
          font-weight: 600;
          line-height: 140%;
        }
        &-des {
          color: #292f33 !important;
          font-family: 'Inter' !important;
          font-size: 16px !important;
          font-weight: 400 !important;
          &-sub {
            color: #292f33;
            font-family: 'Inter';
            font-size: 16px;
            font-style: italic;
            font-weight: 200;
          }
        }

        &-bottom {
          padding-top: 20px;
        }
      }
      &-list-checkin {
        max-width: 780px;
        display: flex;
        gap: 12px;
        padding: 12px 20px 28px 20px;
        border-radius: 8px;
        margin-top: 12px;
      }
      &-search {
        background-color: #f6f9fb !important;
      }
    }
  }
}
