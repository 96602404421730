.salon {
  &__online-appointment-new {
    .salon__form-input-number-format .ant-form-item-control-input-content {
      height: 56px;
      background-color: #f4faff;
      font-size: 20px !important;
    }
    .salon__form-input-number-container input {
      background-color: #f4faff;
      margin: 0;
      padding: 0 10px;
      border-radius: 8px;
      font-size: 20px !important;
    }
    .salon__form-input-number-container input::placeholder {
      font-size: 20px !important;
    }

    .salon__form-input .ant-input {
      height: 56px;
      background-color: #f4faff;
      font-size: 20px;
    }
    .salon__form-input .ant-input::placeholder {
      font-size: 20px !important;
    }

    .salon__form-date-picker .ant-picker {
      height: 56px;
      background-color: #f4faff;
    }
    .salon__form-date-picker .ant-picker-input input {
      font-size: 20px !important;
    }
    &-checkbox {
      .ant-checkbox-inner {
        width: 49px !important;
        height: 45px !important;
        border: 1px solid #000;
        border-radius: 12px;
      }
      .ant-checkbox-inner::after {
        left: 19px;
        height: 20px !important;
        width: 10px !important;
      }
    }
    &-container-information {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 1199px) {
        width: 800px;
      }
      @media (max-width: 767.98px) {
        width: auto;
      }
    }

    &-back {
      cursor: pointer;
      top: 10px;
      position: absolute;
      color: #292f33;
      font-family: 'Inter', 'sans-serif';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      display: flex;
      left: 0;
      text-decoration: none;
      gap: 20px;
    }

    &__note {
      font-size: 20px;
      font-weight: 400;
    }

    &__title {
      font-size: 32px;
      font-weight: 600;
      color: #292f33;
      margin-bottom: 40px;
      text-align: center;
    }
  }
}

@media (max-width: 767px) {
  .salon {
    &__online-appointment-new {
      &-back {
        display: none;
      }

      &-checkbox {
        .ant-checkbox-inner {
          width: 39px !important;
          height: 35px !important;
          border: 1px solid #000;
          border-radius: 12px;
        }
        .ant-checkbox-inner::after {
          left: 12px;
          height: 20px !important;
          width: 10px !important;
        }
      }

      &-container {
        .ant-form-item-control-input-content {
          margin-left: 10px;
          margin-right: 10px;
          height: 40px !important;

          input,
          input::placeholder {
            font-size: 16px !important;
          }
        }
      }

      &__note {
        font-size: 16px;
        text-align: justify;
      }

      &__title {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }

    &__appointment {
      &-button > button {
        margin-top: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .salon {
    &__online-appointment-new {
      &-container {
        .ant-form-item-control-input-content {
          input,
          input::placeholder {
            font-size: 18px !important;
          }
        }
      }
    }
  }
}
