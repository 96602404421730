.custom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}
.btn-cancel {
  color: #b4b6b8;
  border-color: #b4b6b8;
}

.btn-delete {
  color: #eb4c3b;
  border-color: #eb4c3b;
}

.btn-link {
  &:hover {
    background-color: rgba(0, 0, 0, 0.06) !important;
  }
}

.custom-btn[disabled],
.custom-btn:disabled {
  background-color: #d7d9db;
  color: #ffffff !important;
  border-color: unset;
  box-shadow: none;
  border: none !important;
}
