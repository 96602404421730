.background-color-F6F9FB {
  background-color: #f6f9fb;
}

.background-color-0090FF1A {
  background: #0090ff1a;
}

.custom-btn.background-color-0090FF1A:hover {
  p {
    color: #ffffff;
  }
}

.background-color-EE7E68 {
  background-color: #ee7e68;
}

.background-color-CCF4FF {
  background-color: #ccf4ff;
}
.border-bottom-c8cacc {
  border-bottom: 2px solid #c8cacc;
}
.salon {
  &__checkout {
    &__pay-n-complete-modal {
      .ant-modal-content {
        padding: 0;
        .ant-modal-header,
        .ant-modal-body {
          padding: 20px 40px;
        }
        .ant-modal-header {
          margin: 0;
        }
        .ant-modal-footer {
          margin-top: 0;
        }
      }
      &__cash-payment {
        .salon__form-input-number {
          margin: 0;

          .ant-row {
            .ant-col {
              max-width: 100%;
            }
          }
        }

        .salon__label-required {
          &::after {
            display: inline-block;
            margin-inline-end: 4px;
            margin-left: 5px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
          }
        }

        .StripeElement {
          height: 42px;
          padding: 10px 12px !important;
          border-radius: 8px;
          border: 1px solid #78797a;
          &--focus {
            border-color: #0090ff;
          }
          &--invalid {
            border-color: #eb4c3b;
          }
        }
      }
      &__other {
        .ant-col-14 {
          max-width: 100% !important;
        }
      }
      & .salon__custom-table {
        border: 0;
      }
      &__table {
        & .ant-table {
          background: #f6f9fb;

          & .ant-table-thead,
          & .ant-table-tbody {
            & .ant-table-cell {
              background-color: transparent;
            }
          }

          & .ant-table-thead {
            & .ant-table-cell {
              padding: 8px 20px;
            }
          }
        }
      }
      &__tip-screen {
        .ant-modal-close {
          display: none;
        }
      }
      &__require-password {
        .ant-form-item-row {
          .ant-form-item-control {
            max-width: unset;
          }
        }
        .ant-modal-footer {
          padding: 20px 40px;
          > .ant-btn {
            width: 100px;
            height: 38px;
            font-size: 16px;
            font-weight: 700;
            border-radius: 8px;
            &.ant-btn-default {
              border: 1px solid var(--grey-500, #b4b6b8);
              color: #b4b6b8;
            }
          }
        }
      }
    }
    &__primary-outlined-btn {
      border-color: #0090ff;
      color: #0090ff;
    }
  }
}

.slelect-technician .ant-form-item-row {
  display: flex;
  flex-direction: column;
}
