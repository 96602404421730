$font-primary: 'Inter', sans-serif;

.salon__promotions-select-technician-modal {
  .ant-modal-content {
    padding: 0;
    .ant-modal-header {
      padding: 20px;
    }
    .ant-modal-header {
      margin: 0;
    }
  }
  &-card {
    gap: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 6px;
    &-main {
      cursor: pointer;
      flex: calc(100% / 3 - 6px);
      max-width: calc(100% / 3 - 6px);
      border-radius: 8px;
      border: 2px solid transparent;
      &-active {
        border-color: #0090ff;
      }
      &.ant-card .ant-card-body {
        display: flex;
        align-items: center;
        padding: 12px 20px 12px 20px;
        border-radius: 8px;
        border: 1px;
        gap: 8px;
        background-color: rgba(255, 216, 216, 1);
      }
    }

    &-name {
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 0;
      overflow: hidden;
      max-width: 10ch;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
