.salon__checkout-cash-drawer-modal {
  .ant-modal-footer {
    padding: 20px 40px;
  }
  .ant-modal-content {
    padding: 0;
    .ant-modal-header,
    .ant-modal-body {
      padding: 0 40px;
    }
    .ant-modal-header {
      padding: 20px 40px;
      margin: 0;
    }
  }
  &-table {
    margin-bottom: 20px;
    .salon__custom-table {
      border-top: 0;
    }
    .ant-table .ant-table-thead .ant-table-cell {
      padding-top: 0;
      padding-bottom: 0;
      &:focus,
      &:active {
        background-color: transparent !important;
      }
    }

    .salon__custom-table {
      .ant-table {
        .ant-table-tbody {
          .ant-table-cell-row-hover {
            background-color: transparent !important;
          }
          .ant-table-row {
            border-radius: 8px !important;
            &:hover {
              border-radius: 8px !important;
              background-color: unset !important;
            }
          }
        }
      }
    }
  }
  &-custom-input {
    height: 100px !important;
    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
  }
  &-custom-input-close-out {
    height: 294px !important;
  }
  &-title {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(41, 47, 51, 1);
  }
  &-closeout-table {
    border-radius: 8px;
    background-color: #f6f9fb;
    height: 294px;
    padding: 0 !important;
    &-top {
      padding: 20px 40px 8px 40px;
      &-col {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
      }
    }
    &-bottom {
      border-top: 2px solid #d7d9db;
      padding: 8px 40px 20px 40px;
      &-col {
        display: flex;
        justify-content: space-between;
      }
    }
    &-title {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    &-des {
      display: flex;
      font-size: 14px;
      margin-bottom: 8px;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .salon__custom-table .ant-table .ant-table-tbody .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #d7d9db;
    border-radius: unset !important;
    .salon__checkout-cash-drawer-modal-table-custom-text {
      display: flex;
      &-blue {
        color: #0090ff;
      }
    }
  }
}
