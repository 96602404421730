@import '../FormCheckbox/formCheckbox.scss';

.salon__form-checkbox-group {
  .ant-checkbox-group-item {
    font-size: 16px;
    font-weight: 400;
    > span:nth-child(2) {
      padding: 0 !important;
      margin-left: 8px;
    }
  }
}
