@for $i from 0 through 4000 {
  .width-#{$i} {
    width: #{$i}px !important;
  }
  .height-#{$i} {
    height: #{$i}px !important;
  }
  .min-width-#{$i} {
    min-width: #{$i}px !important;
  }
  .min-height-#{$i} {
    min-height: #{$i}px !important;
  }
  .max-width-#{$i} {
    max-width: #{$i}px !important;
  }
  .max-height-#{$i} {
    max-height: #{$i}px !important;
  }
}
@for $i from 1 through 64 {
  .font-size-#{$i} {
    font-size: #{$i}px !important;
  }
}
@for $i from 1 through 64 {
  .width-#{$i}-ch {
    width: #{$i}ch !important;
  }
}

@for $i from 0 through 700 {
  .top-#{$i} {
    top: #{$i}px;
  }
  .bottom-#{$i} {
    bottom: #{$i}px;
  }
  .right-#{$i} {
    right: #{$i}px;
  }
  .left-#{$i} {
    left: #{$i}px;
  }

  .top-#{$i}-n {
    top: -#{$i}px;
  }
  .bottom-#{$i}-n {
    bottom: -#{$i}px;
  }
  .right-#{$i}-n {
    right: -#{$i}px;
  }
  .left-#{$i}-n {
    left: -#{$i}px;
  }
  // line-height
  .line-height-#{$i} {
    line-height: #{$i}px;
  }

  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }
  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .m-l-#{$i} {
    margin-left: #{$i}px;
  }
  .m-r-#{$i} {
    margin-right: #{$i}px;
  }
  .m-#{$i} {
    margin: #{$i}px;
  }
  .m-t-b-#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }
  .m-l-r-#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }

  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }
  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .p-l-#{$i} {
    padding-left: #{$i}px !important;
  }
  .p-r-#{$i} {
    padding-right: #{$i}px !important;
  }
  .p-#{$i} {
    padding: #{$i}px !important;
  }
  .p-t-b-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }
  .p-l-r-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }

  .m-t-#{$i}-n {
    margin-top: -#{$i}px;
  }
  .m-b-#{$i}-n {
    margin-bottom: -#{$i}px;
  }
  .m-l-#{$i}-n {
    margin-left: -#{$i}px;
  }
  .m-r-#{$i}-n {
    margin-right: -#{$i}px;
  }
  .m-#{$i}-n {
    margin: -#{$i}px;
  }
  .m-t-b-#{$i}-n {
    margin-top: -#{$i}px;
    margin-bottom: -#{$i}px;
  }
  .m-l-r-#{$i}-n {
    margin-left: -#{$i}px;
    margin-right: -#{$i}px;
  }
  .gap-#{$i} {
    gap: #{$i}px !important;
  }

  .border-radius-#{$i}px {
    border-radius: #{$i}px !important;
  }
}

.m-l-auto {
  margin-left: auto;
}
.m-r-auto {
  margin-right: auto;
}

.m-t-auto {
  margin-top: auto;
}

.m-b-auto {
  margin-bottom: auto;
}

.m-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.z-index-1 {
  z-index: 1;
}

.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.z-index-1000 {
  z-index: 1000;
}

.font-italic {
  font-style: italic !important;
}
.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.word-break {
  word-break: break-all;
}
.word-break-keep-all {
  word-break: keep-all;
}

.overflow-hidden {
  overflow: hidden;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-transform-first-letter-capitalize {
  display: inline-block;
  &::first-letter {
    text-transform: capitalize;
  }
}

.color-B4B6B8 {
  color: #b4b6b8;
}

.fill-white {
  svg {
    path {
      fill: #ffffff;
    }
  }
}

.border-circle {
  border-radius: 50% !important;
}

.color-78797A {
  color: #78797a;
}

.color-292F33 {
  color: #292f33 !important;
}

.color-A0A2A3 {
  color: #a0a2a3;
}

.color-FFFFFF {
  color: #ffffff;
}

.color-000000 {
  color: #000000;
}
.color-00297A {
  color: #00297a;
}
.background-color-0090FF {
  background-color: #0090ff !important;
}
.background-color-000000 {
  background-color: #000000;
}
.color-64D86F {
  color: #64d86f;
}

.background-color-E9F5FD {
  background-color: #e9f5fd;
}

.background-color-D7D9DB {
  background-color: #d7d9db;
}

.background-color-F6F9FB {
  background-color: #f6f9fb;
}

.background-color-00297A {
  background-color: #00297a !important;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.w-full {
  width: 100% !important;
}
.min-w-full {
  min-width: 100% !important;
}

.w-max {
  width: max-content !important;
}

.w-auto {
  width: auto !important;
}

.h-full {
  height: 100%;
}

.width-fit-content {
  width: fit-content !important;
}

.resize-none {
  resize: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.border-top-dotted {
  border-top: 1px dotted #000000;
}

.border-none {
  border: none !important;
}

.border-color-transparent {
  border-color: transparent !important;
}

.border-color-00297A {
  border-color: #00297a !important;
}

.border-color-0090FF {
  border-color: #0090ff !important;
}

@for $i from 0 through 10 {
  .grid-template-columns-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
  }
  .text-max-#{$i}-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: #{$i}; /* number of lines to show */
    line-clamp: #{$i};
    -webkit-box-orient: vertical;
  }
  .flex-#{$i} {
    flex: #{$i};
  }
}

.font-style-italic {
  font-style: italic;
}

.height-unset {
  height: unset;
}

.color-0090FF {
  color: #0090ff;
}

.color-EB4C3B {
  color: #eb4c3b;
}

.color-37BF50 {
  color: #37bf50;
}

.color-56595C {
  color: #56595c;
}

.width-min-content {
  width: min-content;
}

.max-width-unset {
  max-width: unset !important;
}

.max-width-full {
  max-width: 100% !important;
}

.background-transparent {
  background: transparent !important;
}

.white-space-pre-line {
  white-space: pre-line;
}
.white-space-nowrap {
  white-space: nowrap;
}
.salon__custom-scroll-bar {
  overflow: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #dbdddf;
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #00297a;
    border-radius: 8px;
  }
}
.salon__background-noise {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
  }
}

.salon__text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
