$font-primary: 'Inter', sans-serif;
$rating-color: #e0b005;
$gray-color-2: #292f33;
$blue-color-2: #3fb6ff;
$blue-color-3: #ccf4ff;
$white-color: #ffffff;
$gray-color-3: #d7d9db;
.salon__checkin {
  &-card {
    &-container {
      padding: 8px 12px;
      width: 176px;
      min-height: 107px;
      border-radius: 4px;
      position: relative;
      z-index: 10;
    }
    &-expand-button {
      // background-color: $blue-color-3;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &-history {
      &-header {
        border-bottom: 1px solid $gray-color-3;
        padding-bottom: 8px;
        padding-top: 8px;
      }
      &-container {
        padding: 8px 12px 0 12px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        // background: $blue-color-3;
        width: 100%;
        &-detail {
          margin-bottom: 10px;
        }
      }
      &-detail {
        background-color: $blue-color-2;
        border-radius: 4px;
        text-align: center;
      }
    }
    &-rate {
      display: flex;
      // gap: 3px;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      right: -5px;
      top: 0;
      height: 100%;
      // svg {
      //   margin-top: -2px;
      // }
      &.zero-star {
        color: #b4b6b8 !important;
        .salon__checkin-card-rate-title {
          color: #b4b6b8 !important;
        }
        svg {
          path {
            fill: #b4b6b8 !important;
          }
        }
      }
      &-icon {
        height: 100%;
        display: flex;
        align-items: center;
        margin-top: -2px;
      }
      &-title {
        color: $rating-color;
        text-align: center;
        font-family: $font-primary;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 16.8px */
        // margin-top: 3px;
        margin-bottom: 0;
        height: 100%;
      }
    }
    &-field-value {
      color: $gray-color-2;
      font-family: $font-primary;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 16.8px */
      margin: 0;
      overflow: hidden;
      max-width: 75ch;
      white-space: nowrap;
      text-overflow: ellipsis;
      &-history {
        &-detail {
          color: $gray-color-2;
          font-size: 8px;
          font-style: normal;
          font-weight: 500;
          max-width: 15ch;
          padding: 8px;
        }
        &-header {
          color: $gray-color-2;
          font-size: 8px;
          font-style: normal;
          font-weight: 300;
        }
      }
    }
    &-technician {
      &-container {
        padding: 4px 8px;
        border-radius: 4px;
      }
      &-title {
        color: $gray-color-2;
        font-family: $font-primary;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 16.8px */
        margin: 0;
      }
      &-name {
        color: $gray-color-2;
        font-family: $font-primary;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 16.8px */
        margin: 0;
        overflow: hidden;
        max-width: 10ch;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &-status {
      color: $gray-color-2;
      font-family: $font-primary;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 16.8px */
      margin-top: 5px;
      margin-bottom: 0px;
    }
    &-history {
      color: $gray-color-2;
      font-family: $font-primary;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      text-align: center;
      margin: 0;
    }
  }
}
