.salon__col-wrap {
  &-fit-content {
    flex: unset !important;
  }
  &-scroll {
    overflow: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
  }
}
