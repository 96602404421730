.salon__time-item {
  width: 254px;
  min-height: 475px;
  border-radius: 6px;
  background-color: #e5e5e5;
  padding: 20px;
  text-align: center;
  &-text {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #292f33;
  }
  &-main {
    margin-top: 20px;
  }
  &-date {
    background-color: #fff;
    width: 215px;
    height: 30px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }
    &-active {
      background-color: #0090ff;
      color: #fff;
    }
    &-disabled {
      color: #cecece;
      text-decoration: line-through;
    }
  }
}
