.salon__appointment {
  &-deposit-modal {
    .ant-modal-footer {
      padding: 20px 40px;
    }
    .ant-modal-content {
      padding: 0;
      .ant-modal-close {
        top: 20px;
        right: 40px;
        width: 24px;
        height: 24px;
      }
      .ant-modal-header,
      .ant-modal-body {
        padding: 20px 40px;
      }
      .ant-modal-body {
        padding-bottom: 0;
      }
      .ant-modal-header {
        margin: 0;
      }
    }
    .salon__form-input-number-with-prefix input {
      margin-left: 32px;
    }
    .StripeElement {
      height: 42px;
      padding: 10px 12px !important;
      border-radius: 8px;
      border: 1px solid #78797a;
      margin-bottom: 24px;
      &--focus {
        border-color: #0090ff;
      }
      &--invalid {
        border-color: #eb4c3b;
      }
    }
  }
  &-container-label {
    color: #292f33;
    font-family: 'Inter', sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    display: flex;
    float: left;
    &::after {
      content: '*';
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      margin-left: 5px;
      line-height: 25px;
    }
  }
}
