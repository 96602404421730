.salon__multiple-card {
  & .ant-modal-content {
    padding: 0;

    & > .ant-modal-header {
      padding: 20px 40px !important;

      & > .ant-modal-title {
        & > div {
          margin: 0;
        }
      }
    }

    & > .ant-modal-body {
      padding-bottom: 40px;
    }
  }

  &-form-input {
    & > .salon__form-input-container-label {
      width: auto;
      padding-bottom: 0 !important;
      min-height: auto;
    }

    & .salon__form-input-number-with-prefix {
      margin-inline-end: 0;

      & .ant-form-item-control-input {
        width: 332px;
      }
    }
  }
}
