$color-fff: #fff;
$color-00297A: #00297a;
$color-0090FF: #0090ff;
$color-D7D9DB: #d7d9db;
$color-78797A: #78797a;
$color-56595C: #56595c;
$color-A0A2A3: #a0a2a3;
$color-EBF4FA: #ebf4fa;
$color-292F33: #292f33;
$color-E0B005: #e0b005;
$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$pink-color: #ffd8d8;
$yellow-color: #fceaaa;
$blue-color: #dcfff9;
$green-color: #eeffda;
$gray-color-2: #292f33;
$gray-color-3: #b4b6b8;
$error-color: #eb4c3b;
$color-00297A: #00297a;
$color-B4B6B8: #b4b6b8;
$border-radius: 4px;
$rbc-resource-width: 176px;

.salon__appointment {
  &-customer-picker-search-input {
    .ant-input {
      min-width: 286px !important;
    }
  }

  &-customer-picker-list {
    width: 100%;
    height: 357px;
    margin-top: 20px;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    gap: 8px;
    padding: 14px 13px;
    border-radius: 4px;
    border: 1px solid var(--grey-300, #d7d9db);
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
  }
  &-modal {
    &-btn-grp {
      svg {
        path {
          fill: $color-0090FF;
        }
      }
    }
    &-deposit {
      &-container {
        .salon__form-input-number {
          margin: 0 !important;
          .ant-form-item-control-input {
            border: unset !important;
          }
        }
      }
      &-value {
        display: flex;
        align-items: center;
        gap: 4px;
        svg {
          margin-top: -4px;
        }
      }
      &-label {
        height: 35px;
        padding-bottom: 8px;
        color: #292f33;
        font-family: Inter;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
      }
      &-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #78797a;
        border-radius: 8px;
        padding: 0 12px;
        height: 42px;
      }
      &-history {
        border: 1px solid #78797a;
        border-radius: 8px;
        margin-bottom: 5px;
        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #d7d9db;
          padding: 0 12px;
          height: 42px;
        }
      }
    }
    &-create {
      .ant-modal-content {
        padding: 0;
      }
      .ant-modal-header,
      .ant-modal-footer {
        padding: 20px 40px;
        margin: 0;
      }
      .ant-modal-body {
        overflow-y: auto;
      }

      .ant-tabs-nav {
        height: 60px;
        border-bottom: 5px solid #00297a;
      }

      .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 5px !important;
      }

      .ant-tabs-ink-bar {
        height: 4px !important;
        background-color: #00297a !important;
      }

      &-calendar {
        .ant-modal-body {
          max-height: 600px;
          height: 600px;
          .salon__appointment-calendar {
            height: 465px;
            .rbc-calendar {
              height: 475px;
            }
          }
        }

        .salon__appointment {
          &-filter-technician-item {
            height: 52px;
            min-width: 176px;
            &:hover {
              padding: 0 12px !important;
            }
          }
          &-filter-technician-name {
            margin-bottom: 0;
          }
          &-filter-technician-detail {
            display: none;
          }
          &-filter-technician-avatar {
            min-width: 32px;
            width: 32px;
            height: 32px;

            & .ant-image {
              width: 32px !important;
              height: 32px !important;

              &-img {
                height: 32px !important;
              }
            }
          }
          &-calendar-filter-button-sub {
            min-height: 52px !important;
            padding: 17px 14px !important;
            top: 121px;
          }
        }
      }
      &-col {
        &-left {
          border-left: 1px solid #d7d9db;
        }
      }
    }
    &__select-technician {
      .ant-select-disabled {
        .ant-select-selector {
          background-color: transparent !important;
          .ant-select-selection-item-content {
            color: initial !important;
          }
        }
      }
    }
  }
  &-modal-input-suffix {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(-12px, -50%);
  }
  &-customer-picker {
    // width: 349px;
    // padding: 22px;
  }

  &-calendar-filter-button-sub {
    top: 108px;
    position: absolute;
    padding: 20px 14px;
    background: $color-00297A;
    min-height: 67px;
    max-width: 54px;
    border-radius: $border-radius;
    z-index: 10;
  }
  &-filter-days-item-today {
    width: fit-content;
  }
  &-modal-tab-content {
    display: flex;
    height: 52px;
    border-radius: 4px;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    background-color: #00297a !important;
    color: white;

    .ant-image-img {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      margin-left: 10px;
    }

    span {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &-filter-days-wrapper-month {
    width: fit-content;
  }
  &-form {
    .ant-form-item-label {
      height: 35px !important;
      label {
        color: $gray-color-2;
        font-family: $font-primary;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500;
        display: flex;
        float: left;
      }
    }
  }

  &-button-group {
    .ant-btn {
      height: 42px;
      width: 102.9px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid $gray-color;
      background-color: white;
      color: #b4b4b8;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0px;
    }
  }

  &-button-group-confirmed {
    &:hover {
      background-color: $pink-color;
      color: white;
    }
    &.active {
      background-color: $pink-color;
      color: #292f33;
    }
  }

  &-button-group-checkedIn {
    &:hover {
      background-color: $yellow-color;
      color: white;
    }
    &.active {
      background-color: $yellow-color;
      color: #292f33;
    }
  }

  &-button-group-served {
    &:hover {
      background-color: $blue-color;
      color: white;
    }
    &.active {
      background-color: $blue-color;
      color: #292f33;
    }
  }

  &-button-group-completed {
    &:hover {
      background-color: $green-color;
      color: white;
    }
    &.active {
      background-color: $green-color;
      color: #292f33;
    }
  }

  &-deposit-button {
    width: 368px;
    height: 36px;
    border: 1px solid;

    &.active {
      background-color: #e9f5fd;
      color: #292f33;
    }
  }

  &-modal-customer-information {
    width: 100%;
    height: 69px;
    border-radius: 8px;
    background-color: #ebf4fa;
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
      width: 271px;
      height: 46px;
      justify-content: space-between;
    }

    &-rating {
      justify-content: center;
      align-items: center;
      display: flex;
      &.zero-star {
        color: #b4b6b8 !important;
        svg {
          path {
            fill: #b4b6b8 !important;
          }
        }
      }
    }
  }

  &-modal-cancel-button {
    height: 38px;
    border-radius: 8px;
    border: 1px solid #b4b6b8;
    color: #b4b6b8;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
  }

  &-modal-customer-information-rating {
    color: #e0b005;
  }

  &-modal {
    &-total {
      &-disabled {
        border-color: #d9d9d9;
        background-color: rgba(0, 0, 0, 0.04);
        .ant-form-item-control-input-content {
          input {
            cursor: not-allowed;
            color: #b4b6b8 !important;
            &::placeholder {
              color: #b4b6b8 !important;
              font-size: 16px !important;
            }
          }
        }
      }
    }
    &-estimate {
      .salon__form-input-number-suffix {
        right: 2px;
      }
      &-disabled {
        border-color: #d9d9d9;
        background-color: rgba(0, 0, 0, 0.04);
        .ant-form-item-control-input-content {
          input {
            cursor: not-allowed;
            color: #b4b6b8 !important;
            &::placeholder {
              color: #b4b6b8 !important;
              font-size: 16px !important;
            }
          }
        }
      }
    }
    &-phone-input-until {
      display: flex;
      margin-top: 43px;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      justify-content: start;
      align-items: center;
      color: #78797a;
    }
  }

  &-modal-heading {
    display: flex;
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;

    .ant-btn {
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: #56595c;
    }
  }

  &-item-remove {
    background-color: #00297a;
    border: none;
    padding: 0 !important;
  }

  &-calendar-filter-popover-container {
    top: 10px;
  }

  &-calendar-filter-popover-sub {
    border-radius: $border-radius;
    // margin-top: 23px;
    // inset: 180px auto auto 200px !important;
    &-active {
      background-color: #e7ecf0;
    }

    & .salon__form-search-input {
      width: 258px !important;
    }

    & .ant-popover-inner {
      padding: 0;
      box-shadow: -1px -1px 20px 0px rgba(0, 0, 0, 0.1);
    }

    & .salon__form-search-input {
      border: 1px solid $color-D7D9DB;
      border-radius: $border-radius $border-radius 0 0;
      height: 52px !important;
    }

    & .ant-form-item-control {
      &-input {
        height: 52px !important;
      }
    }

    & .ant-input-affix-wrapper {
      height: 52px;

      & svg {
        width: 16px;
        height: 16px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    & .ant-form-item-control-input-content {
      & span {
        background-color: transparent;
        border-color: transparent !important;
      }

      & .ant-input {
        background: transparent;
        border-color: transparent !important;
        height: 52px;
        font-size: 12px !important;

        &::placeholder {
          font-size: 12px !important;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    &-box {
      border: 1px solid $color-D7D9DB;
      padding: 1px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 52px;
      cursor: pointer;

      &:hover {
        background-color: #e7ecf0;
      }

      &:nth-of-type(2) {
        border-bottom: 0;
        border-top: 0;
      }

      &-text {
        margin-bottom: 0;
      }

      &-subtitle {
        color: $color-B4B6B8;
      }
    }

    &-list {
      overflow-y: auto;
      border: 1px solid $color-D7D9DB;
      border-top: 0;
      max-height: 260px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #dbdddf;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-00297A;
        border-radius: 8px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 4px;
      background-color: $color-00297A;
      padding: 0 8px;
      width: 50%;
      border-radius: $border-radius;
      height: 32px;

      &-wrapper {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0 12px;
        height: 52px;

        &:hover {
          background-color: #e7ecf0;
        }
      }

      &-avatar {
        & .ant-image-img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }

      &-name {
        color: $color-fff;
      }
    }
  }

  &-remove-technician-button {
    position: absolute;
    top: -0px;
    right: -7px;
    border-radius: 50%;
    border: 1px solid $color-78797A;
    background: $color-78797A;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;

    & > span {
      height: 10px;
      display: flex;
      align-items: center;
    }

    &:hover,
    &:active,
    &:focus {
      background: $color-78797A !important;
    }
  }

  &-deposit-modal {
    .salon__form-input-number {
      margin-bottom: 0px !important;
    }
  }

  &-modal-service-check-box {
    .ant-form-item {
      margin-bottom: 0px;
    }
    .salon__form-checkbox {
      .ant-checkbox-inner {
        width: 21px !important;
        height: 21px !important;
        &::after {
          left: 5px !important;
          top: 42% !important;
          height: 12px !important;
          width: 6.5px !important;
        }
      }
    }
  }
}
