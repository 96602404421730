.salon__checkout-delete-ticket-modal {
  &-popup {
    &-title {
      font-family: 'Inter';
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #eb4c3b;
      text-align: center;
      margin-bottom: 8px;
    }
    &-content {
      font-family: 'Inter';
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 19.6px;
      color: #78797a;

      &-strong {
        font-size: 16px;
        font-family: 'Inter';
        font-style: italic;
        font-weight: 600;
        line-height: 20px;
        color: #78797a;
      }
    }
  }
}
