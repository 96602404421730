.salon__check-in {
  -moz-user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  // overflow-x: scroll;
  position: relative;
  overflow-y: hidden;
  // margin-right: 24px;
  -moz-user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  // overflow-x: scroll;
  position: relative;
  overflow-y: hidden;
  // margin-right: 24px;
  margin-left: -6px;
  &-check-in {
    display: flex;
    margin-top: 4px;
    &-container {
      // left: 30px;
      padding-top: 5px;
      position: relative;
      border-right: 1px solid #d7d9db;
      &-card {
        position: relative;
        // margin-right: 6px;
        // margin-left: 6px;
        width: 97%;
        background-color: #f4faff;
        &-titles {
          margin-left: 6px;
          margin-right: 6px;
          width: 224px;
          height: 36px;
          padding: 8px 12px 8px 12px;
          border-radius: 8px;
          gap: 10px;
          background-color: #ffffff;
          margin-bottom: 12px;
          border-radius: 8px;
          box-shadow: -1px -1px 20px 0px rgba(0, 0, 0, 0.1);
          &-border {
            height: 20px;
            border-radius: 8px;
            gap: 10px;
            &-title {
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              text-align: center;
              width: 100%;
              color: #292f33;
            }
          }
        }
        &-main {
          height: calc(100vh - 185px);
          left: 0;
          width: 100%;
          &-icon-more {
            width: 100%;
            text-align: center;
            height: 80px;
            padding-left: 7px;
          }
          &-one {
            // height: 160px;
            width: 176px;
            margin-bottom: 8px;
            margin-left: 10px;
            display: flex;
            background-color: #eeffda;
            border-radius: 5px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-right: 12px;
            padding-left: 12px;
            &-content {
              transition: max-height 0.3s ease-in-out;
              width: 152px;
              // height: 200px;
              display: inline-block;
              padding: 8px 0px 8px 0px;
              border-radius: 4px;
              gap: 8px;
              background-color: #ccf4ff;
              &-history {
                padding: 10px;
                width: 152px;
                // height: 145px;
                padding: 0px 12px 0px 12px;
                gap: 10px;
                &-history {
                  width: 128px;
                  // margin-bottom: 10px;
                  // height: 27px;
                  border-radius: 4px;
                  background-color: #3fb6ff;
                  margin-bottom: 10px;
                  &-header {
                    width: 128px;
                    height: 27px;
                    padding: 5px 15px 15px 18px;
                    border: 0px 0px 1px 0px;
                    gap: 10px;
                    border-bottom: 1px solid #d7d9db;
                    &-p {
                      height: 11px;
                      font-size: 8px;
                      font-weight: 300;
                      line-height: 11px;
                      letter-spacing: 0px;
                      text-align: left;
                      color: #ffffff;
                    }
                  }
                  &-content {
                    display: flex;
                    width: 128px;
                    height: 27px;
                    padding: 8px;
                    &-left {
                      width: 51px;
                      height: 11px;
                      gap: 8px;
                      float: left;
                      &-p {
                        width: 51px;
                        height: 11px;
                        gap: 8px;
                        font-family: Inter;
                        font-size: 8px;
                        font-weight: 500;
                        line-height: 11px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #ffffff;
                      }
                    }
                    &-right {
                      float: right;
                      height: 11px;
                      font-family: Inter;
                      font-size: 8px;
                      font-weight: 600;
                      line-height: 11px;
                      letter-spacing: 0px;
                      text-align: right;
                      color: #ffffff;
                      width: 100%;
                    }
                  }
                }
              }
            }
            &-one-container {
              width: 262px;
              &-customer {
                margin-bottom: 4px;
                width: 130px;
                height: 17px;
                font-family: Inter;
                font-size: 12px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
                color: #292f33;
              }
              &-service {
                margin-bottom: 10px;
                color: #292f33;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 16.8px */
              }
            }
            &-star {
              gap: 4px;
              // justify-content: center;
              align-items: center;
              width: 100%;
              display: flex;
              height: 17px;
              margin-bottom: 4px;
              &-time {
                font-family: Inter;
                font-size: 12px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
                color: #292f33;
              }
              &-count-star {
                color: #e0b005;
                // padding-left: 5px;
                // padding-top: 1.5px;
              }
              &-star {
                margin-top: -3.5px;
                margin-left: -4px;
              }
            }

            &-background {
              // border: 1px solid #b8f8b1;
              // background-color: #b8f8b1;
              margin-bottom: 10px;
              border-radius: 4px;
              width: 100%;
              height: 22px;
              justify-content: center;
              align-items: center;
              display: flex;

              &-right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: #292f33;
                // display: block;
                margin-right: 4px;
                text-align: right;
                font-weight: bold;
                width: 50%;
                font-family: Inter;
                font-size: 10px;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: 0px;
                text-align: right;
                margin-top: 4px;
              }

              &-left {
                color: #292f33;
                margin-top: 4px;
                margin-left: 4px;
                text-align: left;
                width: 50%;
                font-family: Inter;
                font-size: 10px;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0px;
                text-align: left;
              }
            }

            &-status {
              font-family: Inter;
              font-size: 12px;
              font-weight: 700;
              line-height: 17px;
              letter-spacing: 0px;
              text-align: left;
              margin-bottom: 10px;
              &-icon {
                width: 16px;
                height: 16px;
                margin-right: 4px;
              }
              &-text {
                color: #292f33;
                margin-top: 2px;
                font-family: Inter;
                font-size: 12px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
                height: 17px;
              }
            }

            &-drop {
              width: 152px;
              height: 16px;
              justify-content: center;
              align-items: center;
              display: flex;
              gap: 4px;
              align-self: stretch;
              &-text {
                color: #292f33;
                text-align: center;
                height: 14px;
                font-family: Inter;
                font-size: 10px;
                font-weight: 300;
                line-height: 14px;
                letter-spacing: 0px;
                text-align: left;
              }
            }
          }

          overflow-y: auto;
          &::-webkit-scrollbar {
            height: 0;
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 8px;
          }

          &:hover {
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background: #00297a;

              border: 6px solid #00297a;
              border-radius: 8px;
            }

            &::-webkit-scrollbar-track {
              background: #dbdddf;
              border-radius: 8px;
            }
          }
        }
      }
      // overflow-y: scroll;
    }
  }
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  &:hover {
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: #dbdddf;
    }
  }
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #dbdddf;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00297a;
    border-radius: 8px;
  }
}

.salon__search-check-in {
  margin-bottom: 20px;
  align-items: center;
  position: relative;
  height: 80px;
  // margin-left: 10px;
  // padding: 12px;
  border-radius: 12px;
  background: #ffffff;
  display: flex;
  &-title {
    height: 28px;
    gap: 20px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
  }
  &-form-search {
    height: 40px !important;
    display: flex;
    // margin-left: 25%;
    right: 0;
    &-button {
      padding-left: 20px;
    }
  }
}

.container-history {
  position: absolute;
}
