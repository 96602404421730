.salon__checkout-confirm-close-out {
  &-title {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #292f33;
    text-align: center;
    margin-bottom: 8px;
  }
}
