.salon {
  &__online-appointment-new {
    &-step {
      &-item {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #b4b6b8;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #b4b6b8;
      }

      .ant-steps-item-tail {
        padding: 4px 16px 4px 19px !important;
      }

      .ant-steps-item-wait .ant-steps-item-icon {
        background: transparent;
        border: 1px solid #b4b6b8;
        border-radius: 50%;
      }

      .ant-steps {
        &-icon {
          color: #b4b6b8 !important;
          font-weight: 700;
          font-size: 16px;
          line-height: normal;
        }

        &-item {
          &-active {
            .ant-steps-icon {
              color: #fff !important;
            }
          }

          &-content {
            margin-top: -1px !important;
          }

          &-title {
            margin-top: 8px;
            padding-right: 0;
            // color: #292f33;
            color: #b4b6b8 !important;
            text-align: center;
            font-family: 'Inter';
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          &-finish,
          &-process {
            .ant-steps-item-title {
              color: #292f33 !important;
            }
          }
        }
      }
      .ant-steps-item {
        width: 156px;

        .ant-steps-item-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          .ant-steps-item-icon {
            margin: 0;
          }

          .ant-steps-item-tail {
            margin-inline-start: calc(60px + 36px);
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: 120px;
          }
        }
      }
    }

    &-container {
      max-width: 100%;
      padding: 20px 60px;
      box-shadow: 0px 2px 4px 0px #00000026;
      position: relative;
    }
    &-main {
      background-color: #f4faff;
      max-width: 100%;
      padding: 20px 60px;
      min-height: 100vh;
      .salon__step-two {
        &-right-main {
          width: 550px;
          /* width */
          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #dbdddf;
            border-radius: 8px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #00297a;
            border-radius: 8px;
          }

          .salon__step-two-right-main-item {
            width: 550px;
            flex-wrap: wrap;
            height: unset;
          }
        }

        &-custom {
          & .ant-col-14 {
            max-width: 100%;
          }

          &-booking {
            &-service {
              overflow-x: auto;
              width: 100%;
              padding-bottom: 5px;

              & > .salon__service-item-preview {
                min-width: 176px;
              }

              &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #dbdddf;
              }

              &::-webkit-scrollbar {
                height: 4px;
                background-color: #dbdddf;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #00297a;
              }
            }
          }

          &-service-list {
            overflow-y: auto;
            max-height: 568px;
            display: flex !important;
            justify-content: space-between;
            gap: 20px;

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              border-radius: 10px;
              background-color: #dbdddf;
            }

            &::-webkit-scrollbar {
              width: 4px;
              background-color: #dbdddf;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #00297a;
            }
          }

          &-checkbox {
            &.ant-checkbox-wrapper {
              display: flex;
              align-items: center;
            }

            & .ant-checkbox-inner {
              width: 39px !important;
              height: 30px !important;
              border-radius: 6px !important;

              &:after {
                width: 10px !important;
                height: 17px !important;
                left: 12px !important;
                top: 14px !important;
              }
            }
          }
        }

        &-top {
          display: flex;
        }

        &-bottom {
          margin-top: 40px;
          text-align: end;

          button {
            cursor: pointer;
            width: 184px;
            padding: 16px 28px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 12px;
            display: inline-flex;
            text-decoration: none;

            + button {
              margin-left: 12px;
            }

            span {
              text-align: center;
              font-family: 'Inter', 'sans-serif';
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }

          &-left {
            background: transparent;

            &-text {
              color: #b4b6b8;
            }
          }

          &-right {
            &-text {
              color: #fff;
            }
          }
        }

        &-title {
          color: #292f33;
          font-family: 'Inter';
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .ant-card-bordered.ant-card .ant-card-body {
          padding: 0;
          border-radius: 0 0 8px 8px;
        }

        &-left {
          flex: 430px !important;
          max-width: 430px !important;
          border-right: 1px solid rgba(217, 217, 217, 1);
          padding-right: 30px;

          &-unselect {
            flex: unset !important;
            max-width: unset !important;
            //flex: 100%;
            //max-width: 100%;
          }

          &-wrap {
            cursor: pointer;
            overflow-x: auto;
            margin-top: 24px;
            max-width: 1062px;
            padding-bottom: 24px;

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              border-radius: 10px;
              background-color: #dbdddf;
            }

            &::-webkit-scrollbar {
              // width: 4px;
              height: 4px;
              background-color: #dbdddf;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #00297a;
            }
          }

          &-top {
            &-card {
              display: flex;
              gap: 24px 20px;

              + .salon__step-two-left-top-card {
                margin-top: 24px;
              }

              .ant-collapse {
                width: 100%;

                .ant-space-item {
                  cursor: pointer;
                }
              }
            }

            .salon__service-item-preview {
              flex: calc((100% / 6) - 20px);
              max-width: calc((100% / 6) - 20px);
              min-width: 176px;
              display: flex;
              padding: 30px 10px 8px 10px;
              flex-direction: column;
              gap: 12px;
              box-shadow: -2.66667px 5.33333px 26.66667px 0px rgba(0, 0, 0, 0.1);

              &-title {
                text-align: left;
              }
            }
          }

          &-bottom {
            margin-top: 0 !important;
            width: 351px;
            padding-left: 53px;
            border-right: 1px solid rgba(217, 217, 217, 1);

            &-wrap {
              overflow-y: auto;
              margin-top: 23px;
              padding-top: 12px;
              padding-bottom: 12px;

              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: #dbdddf;
              }

              &::-webkit-scrollbar {
                // width: 4px;
                height: 4px;
                background-color: #dbdddf;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #00297a;
              }
            }

            &-checkbox {
              margin-top: 24px;
              font-size: 16px;

              .ant-checkbox-wrapper {
                display: flex;
                align-items: center;

                .ant-checkbox {
                  .ant-checkbox-inner {
                    width: 39px;
                    height: 30px;
                    border-radius: 6px;

                    &:after {
                      width: 10px;
                      height: 17px;
                      left: 12px;
                      top: 14px;
                    }
                  }
                }
              }

              &-text {
                margin-left: 4px;
                color: #292f33;
                font-family: 'Inter';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */
              }
            }

            &-card {
              display: flex;
              flex-direction: column;
              gap: 26px;
              height: 568px;
              overflow-y: auto;
              /* width */
              &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #dbdddf;
                border-radius: 8px;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #00297a;
                border-radius: 8px;
              }

              .salon__custom-card {
                &.active {
                  border: 2px solid rgba(0, 144, 255, 1) !important;
                }

                position: relative;
                margin-right: 20px;
                // display: flex;
                width: 242px !important;
                height: 56px;
                padding: 12px 19px;
                // justify-content: center;
                // align-items: center;
                border-radius: 8px;
                border: 1px solid #b4b6b8;
                background: #f3ebfa;
                cursor: pointer;
                min-width: 176px;

                .ant-card-head {
                  position: absolute;
                  top: -28px;
                  right: 17px;
                  border-bottom: unset;

                  .ant-card-head-wrapper {
                    position: absolute;
                  }
                }

                &-des {
                  font-family: 'Inter', 'sans-serif';
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  letter-spacing: 0px;
                  margin: 0;
                }
              }

              .ant-card-body {
                display: flex;
                align-items: center;
                // gap: 8px;
                width: 100%;
                height: 100%;
                gap: 0;

                .salon__custom-card-des {
                  margin-left: 8px;
                  max-width: calc(100% - 40px);
                  word-break: break-all;
                }
              }
            }
          }
        }

        &-right {
          flex: unset !important;
          max-width: 510px !important;
          padding-left: 30px;

          &-main {
            &-item {
              overflow-y: auto;
              height: 500px;
              padding-right: 10px;
              //margin-top: 30px;

              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: #dbdddf;
              }

              &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
                background-color: #dbdddf;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #00297a;
              }
            }

            &-data {
              margin-top: 24px;

              .salon__service-item-preview {
                margin-top: 20px;
                flex: calc((100% / 6) - 20px) 1;
                max-width: calc((100% / 6) - 20px);
                min-width: 176px;
                display: flex;
                padding: 30px 10px 8px 10px;
                flex-direction: column;
                height: 98px;
                gap: 12px;
                border-radius: 8px;
                border: 1px solid #292f33 !important;
                box-shadow: -2.66667px 5.33333px 26.66667px 0px rgba(0, 0, 0, 0.1);

                &-title {
                  text-align: left;
                }
              }
            }

            &-no-data {
              text-align: center;
              margin-top: 24px;
            }

            &-des {
              margin-top: 12px;
              color: #dbdddf;
              font-family: 'Inter';
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%;
              /* 22.4px */
            }
          }
        }
      }
    }
    &-back {
      cursor: pointer;
      top: 10px;
      position: absolute;
      color: #292f33;
      font-family: 'Inter', 'sans-serif';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      display: flex;
      left: 0;
      text-decoration: none;
      gap: 20px;

      &-title {
        color: #292f33;
        font-family: 'Inter', 'sans-serif';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
      }
    }

    &__ant-spinning {
      &.ant-spin.ant-spin-spinning {
        max-height: unset;
      }
    }
  }

  &__online-appointment-new {
    &__back-button {
      &:hover {
        background-color: transparent !important;
      }
    }

    &__step {
      &-item {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #b4b6b8;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #b4b6b8;
      }

      .ant-steps-item-tail {
        padding: 4px 16px 4px 19px !important;
      }
      .ant-steps-item-process,
      .ant-steps-item-finish {
        .salon__online-appointment-new__step-item {
          background-color: #0090ff;
          border: 1px solid #0090ff;
          color: #ffffff;
        }
      }
    }
  }
  &__pick-a-time-modal {
    .ant-modal-content {
      padding: 0;
    }
    .ant-modal-header {
      border-bottom: none;
    }
    .ant-modal-footer {
      margin-top: 0;
      padding-top: 0;
      border-bottom: none;
      border-top: none;
    }
  }
  &__calender-custom {
    .rmdp-header {
      padding: 30px 0 40px 0;
      .rmdp-arrow-container {
        position: absolute;
      }
      .rmdp-header-values {
        position: absolute;
        font-weight: 600;
        font-size: 18px;
      }
      .rmdp-left {
        left: 280px;
      }
      .rmdp-right {
      }
    }
    .rmdp-wrapper {
      background-color: #e5e5e5;
      width: 360px;
      height: 475px;
      border-radius: 15px;
    }
    .rmdp-day-picker {
      padding: 0;
    }
    .rmdp-week {
      gap: 15px;
      margin: 20px 0px;
      .rmdp-day span {
        height: 39px;
        width: 39px;
        font-size: 16px;
        font-weight: 400;
      }
      .rmdp-day.rmdp-today span {
        background-color: #0090ff;
      }
    }
    .rmdp-top-class {
      display: inline-block !important;
    }
    .rmdp-week-day {
      color: #000;
      font-weight: 500;
      font-size: 16px;
    }
    .rmdp-day,
    .rmdp-week-day {
      height: 35px;
      width: 35px;
    }
    .rmdp-calendar {
      padding: 0;
    }
  }
  &__calender-time-custom {
    height: 475px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: #dbdddf;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #00297a;
    }
  }
  &__step-two-right-main-item-deposit {
    height: auto;
    width: 100%;
  }
  &__online-appointment-info-scroll {
    overflow: auto;
    max-width: 1657px;
  }
  &__booking-error {
    border: 1px solid red !important;
  }
}
