$font-primary: 'Inter', sans-serif;
$gray-color-1: #dbdddf;
.salon__search-error {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  &-content {
    color: $gray-color-1;
    text-align: center;
    font-family: $font-primary;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
  }
}
