$font-primary: 'Inter', sans-serif;
$white-color: #ffffff;
$black-color-2: #292f33;
$gray-color-1: #dbdddf;
$background-layout-color: #f4faff;
.salon__styled-collapse {
  &-main {
    background-color: $white-color;

    .ant-collapse-header {
      padding: 0 20px 20px 0 !important;
    }
    .ant-collapse-content-box {
      padding: 0 20px 20px 0 !important;
    }
    .ant-collapse-item {
      border-bottom: unset !important;
      &:first-child {
        .ant-collapse-header {
          padding-top: 20px !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }
}
