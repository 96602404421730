$font-primary: 'Inter', sans-serif;
$black-color: #000000;
$blue-color: #0090ff;
.salon__form-switch {
  .ant-switch {
    height: 20px !important;
    width: 32px !important;
    min-width: 32px !important;
  }
  .ant-switch.ant-switch-checked {
    background: $blue-color;
  }
  .ant-switch-handle {
    top: 1px !important;
  }
  &-box {
    position: relative;
  }
  &-title {
    position: absolute;
    top: 5px;
    left: 30px;
    color: #000;
    font-size: 16px;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
  }
  .ant-form-item-control-input-content {
    display: flex;
  }
}
