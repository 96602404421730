$font-primary: 'Inter', sans-serif;
$black-color: #292f33;
$white-color: #ffffff;
$gray-color: #d7d9db;
.salon__modal-styled {
  // height: 600px !important;
  .ant-modal-title {
    color: $black-color;
    font-family: $font-primary;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ant-modal-close {
    top: 20px;
    right: 40px;
    width: 24px;
    height: 24px;
  }
  .ant-modal-header {
    border-bottom: 1px solid $gray-color;
    padding: 20px 40px !important;
    margin-bottom: 0 !important;
  }
  .ant-modal-footer {
    padding-top: 20px;
    border-top: 1px solid $gray-color;
  }
  .ant-modal-body {
    // max-height: 600px;
    padding: 20px 40px 0 40px;
    // overflow-y: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
  }
}
