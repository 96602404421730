$font-primary: 'Inter', sans-serif;
.salon__print {
  &-bill {
    &-privacy {
      // color: '#292F33';
      // font-feature-settings: 'clig' off, 'liga' off;
      // font-family: $font-primary;
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      // font-style: normal;
      line-height: 150%; /* 12px */
      white-space: pre-line;
      margin-bottom: 2px;
    }
    &-sign-here {
      // color: #292f33;
      // font-feature-settings: 'clig' off, 'liga' off;
      // font-family: $font-primary;
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      font-style: italic;
      line-height: 150%; /* 9px */
      margin-bottom: 0;
    }
    &-container {
      background-color: #ffffff;
      padding: 20px 8px 12px 8px;
    }
    &-strike {
      margin: 1px 0 0 0 !important;
      border-color: #000000;
      border-width: 0.7px 0 0;
    }
    &-qr {
      &-container {
        width: 72px;
        height: 72px;
        border: 1px solid #000000;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #fff;
          border: 1px solid #fff;
          z-index: 1;
          top: 0;
          left: 0;
          transform: rotate(45deg);
        }
      }
      &-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 72px !important;
        height: 72px !important;
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: unset !important;
        border: unset !important;
        z-index: 10;
        canvas {
          width: 65px !important;
          height: 65px !important;
        }
      }
    }
    &-detail {
      // color: #000000;
      // font-feature-settings: 'clig' off, 'liga' off;
      // font-family: $font-primary;
      font-size: 30px;
      font-weight: 600;
      font-style: normal;
      line-height: 150%; /* 12px */
      margin-bottom: 0;
      white-space: pre-line;
    }
    &-title {
      &-services {
        // color: #000000;
        // font-family: $font-primary;
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        // font-style: normal;
        line-height: 150%; /* 12px */
        margin-bottom: 0;
      }
    }
    &-content {
      // color: #000000;
      // font-family: $font-primary;
      font-size: 30px;
      font-weight: 600;
      font-style: italic;
      line-height: 140%;
    }
    &-checkin {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      line-height: 140%;
      &-thankyou {
        // color: #292F33;
        // font-family: $font-primary;
        font-size: 30px;
        font-weight: 600;
        line-height: 150%; /* 12px */
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0;
      }
      &-content {
        // color: #292F33;
        // font-family: $font-primary;
        font-size: 30px;
        font-weight: 600;
        line-height: 150%; /* 12px */
        margin-bottom: 4px;
        white-space: normal;
        word-break: normal;
      }
      &-number {
        text-align: center;
        // color: #292F33;
        // font-family: $font-primary;
        font-size: 160px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 44.8px */
        margin-bottom: 0;
      }
    }
    &-header {
      text-align: center;
      &-content {
        font-size: 30px;
        font-weight: 600;
        line-height: 140%;
        margin-bottom: 4px;
        // color: #292F33;
        // font-family: $font-primary;
      }
    }
    &-title {
      // color: #292F33;
      // font-family: $font-primary;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      text-transform: uppercase;
      margin-bottom: 8px;
    }
  }
}
