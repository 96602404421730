$font-primary: 'Inter', sans-serif;
$white-color: #ffffff;
$black-color: #000000;
$black-color-2: #292f33;
$blue-color: #0090ff;
$blue-color-1: #ebf4fa;
$blue-color-2: #e5f4ff;
$gray-color: #78797a;
$gray-color-1: #dbdddf;
$gray-color-2: #a0a2a3;
$gray-color-3: #78797a;
$error-color: #eb4c3b;
.salon__button-tab {
  display: flex;
  gap: 12px;
  align-items: center;

  &-btn {
    border: unset;
    padding: 8px 12px;
    outline: unset;
    box-shadow: unset;
    line-height: 1.2;
    color: $gray-color-2 !important;
    font-size: 16px;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: auto;
    height: auto;
    min-width: 213px;
    background: #f6f9fb;
    &:active,
    &:focus,
    &:hover {
      outline: unset;
      background: none !important;
    }
  }
  // active button
  .active {
    text-align: center;
    font-family: $font-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    background-color: $blue-color-2 !important;
    &-content {
      color: $blue-color;
    }
  }
}
