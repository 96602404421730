.salon__printer-settings {
  &__ip-address {
    .salon__form-input {
      &-number {
        margin-bottom: 20px !important;
      }
    }
  }

  &__characters-line,
  &__line-space {
    .salon__form-input {
      &-number {
        margin-bottom: 0px !important;
      }
    }
  }
}
