$font-primary: 'Inter', sans-serif;
$blue-color: #0090ff;
$black-color: #292f33;
$white-color: #ffffff;
$gray-color: #78797a;
$gray-color-2: #d7d9db;
.salon__styled-checkbox {
  &-container {
    .ant-checkbox-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      background-color: transparent;
      border-radius: 12px;
      width: 310px;
      height: 119px;
      gap: 12px;
      border: 1px solid $gray-color-2;
      padding: 20px;
      &:hover {
        .ant-checkbox-inner {
          border: unset !important;
        }
      }
      div {
        display: none;
      }
      &-checked {
        background: rgba(0, 144, 255, 0.1);
        border: 1px solid $blue-color;
        padding: 20px;
        .salon__styled-checkbox-content {
          color: $blue-color;
        }
      }
    }
    .ant-checkbox-inner {
      border: unset !important;
      width: 38px !important;
      height: 38px !important;
      border-radius: 50%;
    }
    .ant-checkbox {
      .ant-checkbox-inner {
        background: url('../../../public/assets/qr/icn_check_circle_gray.svg') center no-repeat;
        background-color: $white-color !important;
        background-size: cover;
      }
      &-checked {
        .ant-checkbox-inner {
          background: url('../../../public/assets/qr/icn_check_circle.svg') center no-repeat;
          background-color: rgba(0, 144, 255, 0.1) !important;
          background-size: cover;
          &::after {
            content: unset !important;
          }
        }
        &::after {
          border: unset !important;
          animation-name: none !important;
        }
      }
    }
  }
  &-content {
    color: $gray-color;
    text-align: center;
    font-family: $font-primary;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
