$font-primary: 'Inter', sans-serif;
$white-color: #ffffff;
$black-color-2: #292f33;
$gray-color: #78797a;
$gray-color-1: #dbdddf;
.salon__service {
  &-box-value {
    margin-bottom: 0;
    overflow: hidden;
    &-item {
      margin-bottom: 0;
      overflow: hidden;
      max-width: 9ch;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &-item {
    &-remove {
      width: 16px;
      height: 16px;
      background-color: $gray-color;
      border-radius: 50%;
      padding: 0 !important;
      line-height: 0;
      position: absolute;
      top: -3px;
      right: -8px;
    }
    &-box-value {
      span {
        line-height: 10px;
      }
    }
    &-preview {
      width: 176px;
      height: 98px;
      border-radius: 7px;
      padding: 30px 10px 8px 10px;
      position: relative;
      &-book-mark {
        position: absolute;
        top: 3px;
        left: 0;
      }
      &-services {
        position: absolute;
        left: 7px;
        top: 5px;
        color: $white-color;
        text-align: center;
        font-family: $font-primary;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
      }
      &-title {
        color: $black-color-2;
        font-family: $font-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-detail {
        position: absolute;
        bottom: 6px;
        left: 0;
        width: 100%;
        padding: 0 10px;
      }
    }
  }
}
@media (max-width: 767px) {
  .salon__service-item-preview-title {
    font-size: 12px;
  }
  .salon__service-item-preview-services {
    top: 6px;
    line-height: 140%;
  }
  .salon__service-item-preview-detail .salon__service-box-value {
    font-size: 10px !important;
    line-height: normal;
  }
  .salon__service-box-value svg {
    height: 10px;
  }
}
