.salon__appointment-detail {
  & .ant-modal-content {
    padding: 0;
  }

  &-heading {
    margin-bottom: -8px;
    display: flex;
    align-items: center;
    gap: 8px;

    & .truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 250px;
      display: block;
    }
  }

  &-column {
    &-text {
      display: block;
      text-align: right;
    }
  }

  &-wrapper {
    margin-top: -4px;

    & > .salon__custom-table {
      border: 0;
      margin-bottom: -1px;

      & .ant-table-wrapper {
        border-bottom: 1px solid #d7d9db;

        & .ant-table-cell {
          border: 1px solid #d7d9db;
          border-start-start-radius: unset !important;
          border-start-end-radius: unset !important;
          background: transparent;
        }

        & .ant-table-thead,
        & .ant-table-tbody {
          & .ant-table-cell {
            border-bottom: 0;

            &:not(:last-child) {
              border-right: 0;
            }
          }
        }

        & .ant-table-tbody {
          & .ant-table-cell {
            &:first-child {
              border-top-left-radius: unset !important;
              border-bottom-left-radius: unset !important;
            }

            &:last-child {
              border-top-right-radius: unset !important;
              border-bottom-right-radius: unset !important;
            }
          }
        }
      }
    }
  }

  &-title {
    color: #78797a;
    margin-bottom: 16px;
  }

  &-button {
    padding: 8px 12px;
    margin-top: -4px;

    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 13px;
    }
  }
}
