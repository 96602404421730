.salon__checkout {
  &-merchandise-modal {
    .ant-modal-content {
      padding: 0;
      .ant-modal-header,
      .ant-modal-body {
        padding: 20px 40px;
      }
      .ant-modal-body {
        padding-bottom: 40px;
      }
      .ant-modal-header {
        margin: 0;
        padding: 20px 40px;
      }
      .ant-modal-body {
        padding: 20px 40px 40px 40px;
      }
    }
    &-title {
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(41, 47, 51, 1);
    }
  }
  &-input-checkout-readonly {
    .salon__form-input-container-read-only {
      .ant-form-item-control-input-content {
        input {
          color: #292f33 !important;
        }
      }
    }
    .salon__form-input-container-read-only {
      .salon__form-input-number-prefix {
        svg {
          path {
            fill: #292f33 !important;
          }
        }
      }
    }
  }
}
