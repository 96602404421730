.salon__appointment-checkin-modal {
  & .ant-modal-content {
    border-radius: 24px;
    padding: 17px 0 45px;
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-close {
      right: 20px !important;
    }
  }

  &-heading {
    border-bottom: 2px solid #292f33;
    width: 150px;
    text-align: center;
  }
  &-qr {
    &-image {
      border: 3px solid #292f33;
      width: 190px;
      height: 190px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 190px;
        height: 190px;
        transform: rotate(45deg);
        border: 3px solid #fff;
        background-color: #fff;
      }
    }
    &-container {
      width: 180px !important;
      height: 180px !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ant-qrcode {
        width: 180px !important;
        height: 180px !important;
      }
      canvas {
        width: 164px !important;
        height: 164px !important;
      }
    }
  }

  &-subtitle {
    margin-top: 27px;
  }
}
