$font-primary: 'Inter', sans-serif;
$gray-color-1: #b4b6b8;
$gray-color-2: #292f33;
$white-color: #ffffff;
$blue-color: #0090ff;
.salon__form-radio {
  .ant-form-item-label {
    height: 35px !important;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: $gray-color-2;
    label {
      display: flex;
      float: left;
    }
  }
  .ant-radio {
    .ant-radio-inner {
      background-color: $white-color;
      border-width: 2px;
      &::after {
        background-color: $blue-color;
        transform: scale(0.5);
      }
    }
  }
  .ant-radio-wrapper {
    span {
      &.ant-radio {
        height: 16px;
      }
      &:last-child {
        color: $gray-color-1;
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */

        padding-right: 0;
        display: flex;
      }
    }
    &-checked {
      span {
        &:last-child {
          color: $gray-color-2;
          font-family: $font-primary;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%; /* 20.8px */
        }
      }
    }
  }
}
