$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$white-color: #ffffff;
$blue-color: #0090ff;
$blue-color-2: #2076a8;
$blue-color-3: #043e6a;
$gray-color-2: #a0a2a3;
$text-color: #292f33;
$semi-white-color: #dbdddf;
$background-color: #f4faff;
.salon__qr-code {
  background: url(../../../public/assets/qr/background-booking-online.png) no-repeat center;
  background-size: 100% 100%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &-logo {
    padding: 20px;
  }

  &-main {
    display: flex;
    flex: 1 1;
    padding-top: calc(60 / 1920 * 100vw);
    width: 100%;

    &-left {
      width: 60%;
      text-align: center;

      &-text {
        font-size: 50px;
        font-weight: 700;
        line-height: 61px;
        color: #ffffff;
        padding-bottom: 20px;
        @media (max-width: 1366px) {
          font-size: 40px;
          line-height: 48px;
        }
        @media (max-width: 1199px) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      &-text-checkin {
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
        color: #ffffff;
      }

      &-slice {
        height: 442px;
        margin-left: auto;
        margin-right: auto;
        width: 455px;
        max-width: 100%;
      }
    }

    &-right {
      width: 40%;

      &-form {
        max-width: 100%;
      }

      &-text {
        font-size: calc(40 / 1920 * 100vw);
        font-weight: 400;
        line-height: 48px;
        color: #ffffff;
        padding-bottom: 20px;
      }

      &-custom-button {
        background: linear-gradient(0deg, rgba(4, 61, 172, 0.85), rgba(4, 61, 172, 0.85)),
        linear-gradient(180deg, rgba(187, 180, 180, 0.1) 0%, rgba(0, 0, 0, 0) 100%) !important;
        border: 0.56px solid;
        border-image-source: linear-gradient(180deg, rgba(187, 180, 180, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
        box-shadow: 0 4px 4px 0 #ffffff inset;
      }

      &-custom-input {
        height: 60px !important;
        background: transparent !important;
        border-radius: 0px !important;
        border-top: unset !important;
        border-left: unset !important;
        border-right: unset !important;
        border-bottom: 1px solid #78797a !important;
        color: #fff !important;
        text-align: left !important;

        &::placeholder {
          font-size: 45px !important;
          font-weight: 700 !important;

          margin-bottom: 5px !important;
        }

        &:hover {
          background: transparent;
        }

        &:focus {
          background: transparent;
        }
      }
    }
  }

  .slick {
    &-slide {
      height: 100%;

      img {
        cursor: pointer;
        width: 100%;
        height: 442px;
        object-fit: cover;
      }
    }

    .slick-track {
      display: flex;
      height: calc(100vh - 20px);
    }

    .slick-slider {
      display: flex !important;
    }

    .slick-prev {
      left: -50px;
    }

    &-dots {
      position: relative;
      left: 50%;
      top: 20px;
      transform: translateX(-50%);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      list-style-type: none;
      gap: 4px;

      > li {
        border: 2px solid #fff;
        border-radius: 50%;
        height: 20px !important;
        width: 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline: unset;

        &.slick-active {
          button {
            border-radius: 50% !important;
            opacity: 1 !important;
          }
        }
      }

      button {
        display: block !important;
        padding: 0 !important;
        width: 10px !important;
        height: 10px !important;
        align-items: flex-start !important;
        border: none !important;
        border-radius: 50% !important;
        background: #fff;
        opacity: 0 !important;

        &::after {
          display: none;
        }
      }
    }
  }

  &__image {
    width: 100%;
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    .ant-qrcode {
      width: 454px !important;
      height: 439px !important;
      background-color: #fff !important;
      border-radius: 0 !important;
    }

    &-des {
      font-family: $font-primary;
      font-size: 20px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0;
      text-align: center;
      color: #fff;
      margin-top: 20px;
      z-index: 1;
    }
  }

  .salon__form-input .ant-input {
    padding: 0 14px !important;
  }

  .ant-input-status-error {
    border-color: #ff4d4f !important;
  }
}

@media (max-width: 1199px) {
  .salon__qr-code-main-right,
  .salon__qr-code-main-left {
    width: 50%;
  }
  .salon__qr-code-main-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 1600px) {
  .salon__qr-code-main {
    padding-top: calc(20 / 1920 * 100vw);
  }
}

@media (max-width: 992px) {
  .salon__qr-code-main {
    flex-direction: column;

    .salon__qr-code-main-left, .salon__qr-code-main-right {
      width: 100%;
    }

    .salon__qr-code-main-right {
      text-align: center;

      .salon__qr-code-main-right-form {
        margin: auto;
      }
    }
  }
}
@media (max-width: 768px) {
  .salon__qr-code__image{
    .ant-qrcode{
      width: 420px !important;
      height: 420px !important;
    }
  }
}

.salon__home {
  .slick-slide {
    .slick-slide > div {
      height: 100%;
    }
  }

  .slick-track {
    display: flex;
    height: 100vh;
  }
}
