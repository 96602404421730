.Toastify__toast-container {
  width: unset !important;
  min-width: 250px !important;
  .salon__notification {
    &-error {
      background-color: $color-error-notification;
      color: $color-text-error-notification;
      min-width: 200px;
      height: 56px;
      border-radius: 8px;
      .Toastify__toast-body {
        font-family: $font-primary;
        font-size: 12px;
        font-weight: 600;
        margin: unset !important;
      }
      .Toastify__close-button {
        display: none;
      }
    }
    &-success {
      background-color: $color-success-notification;
      color: $color-text-error-notification;
      min-width: 200px;
      height: 56px;
      border-radius: 8px;
      .Toastify__toast-body {
        font-family: $font-primary;
        font-size: 12px;
        font-weight: 600;
        margin: unset !important;
      }
      .Toastify__close-button {
        display: none;
      }
    }
  }
}
