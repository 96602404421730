.salon__checkout-general-modal {
  .ant-modal-content {
    padding: 0;
    .ant-modal-header,
    .ant-modal-body {
      padding: 20px 40px;
    }
    .ant-modal-header {
      margin: 0;
    }
    .ant-modal-body {
      padding-bottom: 40px;
    }
  }

  &-title {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(41, 47, 51, 1);
  }

  &-card {
    margin-top: 20px;
    gap: 8px;
    display: flex;
    align-items: center;

    &-main {
      position: relative;
      display: flex;
      width: 104px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #b4b6b8;
      background: #f3ebfa;
      cursor: pointer;
      height: 50px;

      .ant-card-body {
        display: flex;
        align-items: center;
        gap: 0 4px;
        padding: 0;
        border-radius: 0 0 8px 8px;
      }
    }

    &-name {
      color: #292f33;
      font-family: 'Inter';
      font-size: 10px;
      font-weight: 500;
      line-height: 15px;
      width: 10ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-button {
      cursor: pointer;
      color: #0090ff;
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      width: 65px;
    }
  }
}

.salon__checkout-general-select-technician-modal {
  .salon__checkout-general-modal-heading {
    padding-left: 20px;
  }
  .ant-modal-content {
    padding: 0;
    .ant-modal-header {
      padding: 20px;
    }
    .ant-modal-header {
      margin: 0;
    }
  }
  &-card {
    gap: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 38px 20px 38px;
    &-main {
      cursor: pointer;
      flex: calc(100% / 3 - 6px);
      max-width: 176px;
      width: 176px;
      border-radius: 8px;
      border: 1px;

      &.ant-card .ant-card-body {
        display: flex;
        align-items: center;
        padding: 12px 20px 12px 20px;
        border-radius: 8px;
        border: 1px;
        gap: 8px;
        background-color: rgba(255, 216, 216, 1);
      }
    }

    &-name {
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 0;
      overflow: hidden;
      max-width: 10ch;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
