$font-primary: 'Inter', sans-serif;
$semi-blue-color: #f4faff;
$white-color: #ffffff;
$gray-color: #78797a;
$gray-color-2: #292f33;
$gray-color-1: #dbdddf;
$blue-color: #0090ff;
.salon__styled-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white-color;
  border-radius: 8px;
  padding: 18px 20px 8px;
  .ant-pagination-item,
  .ant-pagination-jump-next {
    display: none;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    display: block !important;
    margin: 0;
  }
  .ant-pagination-total-text {
    font-size: 16px;
    font-weight: 500;
    font-family: $font-primary;
    color: $gray-color-2;
  }

  .ant-pagination {
    display: flex;
    align-items: center;
  }
  &-box {
    &-container {
      color: $gray-color;
      font-family: $font-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      display: flex;
      gap: 8px;
    }
    &-rows {
      border-radius: 4px;
      background: $semi-blue-color;
    }
  }
  &-btn {
    background-color: transparent;
    border: unset;
    box-shadow: unset;
    width: 49px;
    height: 30px;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    font-family: $font-primary;
    padding: 4px;
    color: $gray-color;
    &::before {
      position: absolute;
      content: '';
      width: 1px;
      height: 100%;
      right: 0;
      top: 0;
      background: $gray-color-1;
    }
    &:last-child {
      &::before {
        position: unset !important;
      }
    }
  }
  .active {
    background-color: $blue-color !important;
    color: $white-color;
    &::before {
      position: unset !important;
    }
  }
}
